import { render } from "./PerfilDocente.vue?vue&type=template&id=05c6d1a0&scoped=true"
import script from "./PerfilDocente.vue?vue&type=script&lang=js"
export * from "./PerfilDocente.vue?vue&type=script&lang=js"

import "./PerfilDocente.vue?vue&type=style&index=0&id=05c6d1a0&lang=css"
import "./PerfilDocente.vue?vue&type=style&index=1&id=05c6d1a0&scoped=true&lang=css"
import "./PerfilDocente.vue?vue&type=style&index=2&id=05c6d1a0&lang=scss&scoped=true"
script.render = render
script.__scopeId = "data-v-05c6d1a0"

export default script