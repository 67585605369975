<template>
   <div class="grid">
		<div class="col-12">
             <Toolbar class="border-round">
                <template #left>
                    <div class="flex align-items-center justify-content-center">
                        <span class="text-lg  font-semibold">Guía </span>
                        <Breadcrumb  :home="null" :model="breadcrumbModel" class="hidden md:block"/>
                    </div>
                </template>
                <template #right>
                    <Button type="button" label="Filtro"  icon="pi pi-filter" class="p-button-info btn-light-primary mr-2" @click="toggle"/>
                    <OverlayPanel ref="filterOP" :breakpoints="{'960px': '75vw', '640px': '100vw'}" :style="{width: '320px'}">
                        <h5 class="font-semibold">Filtro</h5>
                        <Divider />
                        <!--Field : Facultad -->
                        <div class="field">
                            <label for="fac_codigo" >Facultad</label>
                            <Dropdown  id="fac_codigo"  v-model="selectedFacultad" :options="facultadList"   placeholder="Seleccione Facultad" :showClear="true"  class="w-full"  @change="selFacultad_onChange($event)" >
                                <template #value="slotProps">
                                    <div  v-if="slotProps.value">
                                        <span>{{slotProps.value.fac_nombre}}</span>
                                    </div>
                                    <span v-else>
                                        {{slotProps.placeholder}}
                                    </span>
                                </template>
                                <template #option="slotProps">
                                    <span>{{slotProps.option.fac_nombre}}</span>
                                </template> 
                            </Dropdown>
                        </div>
                        <!-- Field : Carrera -->
                        <div class="field">
                            <label for="car_codigo">Carrera</label>
                            <Dropdown id="car_codigo" v-model="selectedCarrera" :options="carreraList" optionLabel="car_nombre" :filter="true" placeholder="Seleccione Carrera" :showClear="true" class="w-full" :loading="loadingCarrera">
                                <template #empty>
                                    <div class="">Seleccione Facultad </div>
                                </template>
                                <template #value="slotProps">
                                    <div  v-if="slotProps.value">
                                        <div>{{slotProps.value.car_nombre}}</div>
                                    </div>
                                    <span v-else>
                                        {{slotProps.placeholder}}
                                    </span>
                                </template>
                                <template #option="slotProps">
                                    <div>
                                        <div>{{slotProps.option.car_nombre}}</div>
                                    </div>
                                </template>
                            </Dropdown>
                        </div>

                        <div class="field">
                            <label for="cil_codigo">Ciclo</label>
                            <Dropdown  id="cil_codigo"  v-model="selectedCiclo" :options="cicloList" placeholder="Seleccione Ciclo" class="w-full" >
                                <template #empty>
                                    Opciones no válida
                                </template>
                                <template #value="slotProps">
                                    <div  v-if="slotProps.value">
                                        <span>{{slotProps.value.cil_descripcion}}</span>
                                    </div>
                                    <span v-else>
                                        {{slotProps.placeholder}}
                                    </span>
                                </template>
                                <template #option="slotProps">
                                    <span>{{slotProps.option.cil_descripcion}}</span>
                                </template> 
                            </Dropdown>
                        </div>

                        <Divider />
                        <div class="flex justify-content-end">
                            
                            <div class="flex">
                                <Button label="Cancelar" icon="pi pi-times"  class="p-button-text  p-button-danger mr-2 text-xs " @click="toggle"/>
                                <Button label="Aceptar" icon="pi pi-check" class="text-xs p-1"  @click="apply_onClick()"  />
                            </div>
                        </div> 
                    </OverlayPanel>
                </template>
            </Toolbar>
            <div class="flex justify-content-between my-2">
                <div class="flex justify-content-start align-items-center">
                   <h6 class="font-bold my-0" >
                       <span > ℹ️</span>
                        La programación de visitas en <span class="text-primary">Seguimiento a la labor</span>! 
                        no requiere un plan académico aprobado. 
                        <a href="#/Plan/" class="" data-v-034bd5e0="">Guía de observación docente.</a>
                        <span > ℹ️</span></h6>
                </div>
                <div class="flex-shrink-0 flex">
                    <span class="p-buttonset">
                        <Button label="" icon="pi pi-user" class="p-button-secondary" :class="{'p-button-outlined': CURRENT_VIEW != VIEW_TYPE.EMPLEADO }" v-tooltip.top="'Docente'" @click="viewMode_onClick(VIEW_TYPE.EMPLEADO)"/>
                        <!-- <Button label="" icon="pi pi-book" class="p-button-primary p-button-text" v-tooltip.top="'Materia'" @click="viewMode_onClick(VIEW_TYPE.GALLERY)"/> -->
                        <Button label="" icon="pi pi-list" class="p-button-secondary"  :class="{'p-button-outlined': CURRENT_VIEW != VIEW_TYPE.LIST }"  v-tooltip.top="'Todos'" @click="viewMode_onClick(VIEW_TYPE.LIST)"/>
                        <Button label="" icon="pi pi-calendar" class="p-button-secondary" :class="{'p-button-outlined': CURRENT_VIEW != VIEW_TYPE.CALENDAR }"   v-tooltip.top="'Programación'" @click="viewMode_onClick(VIEW_TYPE.CALENDAR)"/>
                    </span>
                </div>
            </div>   

            <PerfilDocente :Codigo="CodEmpleado" v-if="CURRENT_VIEW == VIEW_TYPE.EMPLEADO" @view_onEmit="upsertGuia_onClick($event.evt, $event.data)"  :key="perfilDocenteKey"/>
            <Card class="my-1 pb-0" v-else>
                <template #title>
                    <div class="flex justify-content-between align-items-center flex-wrap flex-column md:flex-row" >
                        <div class="flex flex-column mb-3" >
                            <h5 class="mb-0 font-bold text-base sm:text-lg uppercase">Guía de observación del desempeño docente</h5>
                            <small  class="text-muted font-semibold text-base">Administrando {{guiaDocenteList.length}} aplicaciones</small>
                        </div>
                        <div class="flex align-items-end" >
                            <SplitButton label="Nueva aplicación" icon="pi pi-plus-circle"  v-tooltip.top="'Nueva aplicación'" @click="upsertGuia_onClick()"  :model="itemsButtonUpsert" />
                        </div>
                    </div>
                </template>
                <template #content class="pt-0 pb-0">
                    
                    <div class="flex mb-1 flex-column md:flex-row align-items-center" v-if="IsViewDetails">
                        <div class="flex flex-grow-1 flex-shrink-1 my-1">
                            <span class="p-input-icon-left  p-fluid w-full" >
                                <i class="pi pi-search" />
                                <InputText  :placeholder="'Buscar ' + CURRENT_VIEW" v-model="searchTerm" @keyup="handleKeyUp" @keypress="handleKeyPress"  />
                            </span>
                        </div>
                        <div class="mb-1 mx-2 flex justify-content-end ">
                            <SelectButton v-model="selectedStatus" :options="statuses" dataKey="code">
                                <template #option="slotProps">
                                    <div class="font-semibold inline-flex"  v-tooltip.top="slotProps.option.name" :class="'app-'+slotProps.option.code" >
                                        <i class="mr-2 lg:mr-3  md:mr-1 pi pi-" 
                                        :class="[slotProps.option.icon, 'text-' + (slotProps.option.code == 'P' ? 'blue-600' :(slotProps.option.code == 'C' ? 'danger-50' : 'success'))]" ></i>
                                        <div class=" text-xs md:text-base">{{slotProps.option.name}}</div>
                                    </div>
                                </template>
                            </SelectButton>
                        </div>
                    </div>
                    
                    <DataTable :value="guiaDocenteList" stripedRows :rowHover="true" :rows="30" responsiveLayout="scroll" :loading="loadingData"
                        :rowsPerPageOptions="[30,50,100]" v-if="CURRENT_VIEW == VIEW_TYPE.LIST" class="p-datatable-sm">
                        <template #header>
                            <Paginator :rows="10" :totalRecords="paginationList.totalRecords" 
                                template="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                :rowsPerPageOptions="[10,20,50,100]" 
                                currentPageReportTemplate="Mostrando {first} - {last} de {totalRecords} guías" @page="onPage($event)">

                                <template #left>
                                    {{title}}<br>
                                </template>
                            </Paginator>
                        </template>
                        <template #loading>
                            <p  class="font-italic text-center">Espere mientras encontramos los datos...</p>
                        </template>
                        <template #empty>
                            <p  class="font-italic text-center">Al parecer no hay evaluaciones activas que realizar.</p>
                        </template>
                        <Column header="Docente">
                            <template #body="{data}">
                                <div class="flex align-items-center">
                                    <div class="symbol symbol-40 symbol-circle  shadow-3" :class="'symbol-light'+ (data.estado  === 'C' ? '-danger' : (data.estado  === 'A' ?  '-success' : '-primary'))">
                                        <span class="symbol-label font-size-h4"><i class="pi pi-user" /></span>
                                    </div>
                                    <div class="flex flex-column ml-3">
                                        <a href="javascript:;" class="text-primary font-bold text-gray-900" @click="viewDocente_onClick(data.emp_codigo)">{{data.empleado_nombre}}</a>
                                        <!-- <span class="mt-1 font-normal text-gray-600 text-sm">{{data.}} </span> -->
                                        <span class="mt-1 font-semibold text-gray-600 text-sm uppercase">{{data.materia_nombre}} </span>
                                        
                                        <span class="mt-1 font-semibold text-gray-600 text-sm uppercase">{{data.plan_acad_nombre}}</span>
                                    </div>
                                </div>
                            </template>
                        </Column>
                        <Column header="Grupo">
                            <template #body="{data}">
                                <div class="flex flex-column ">
                                    <a href="javascript:;" class="text-primary font-bold text-gray-900">{{data.horario_descripcion}}</a>
                                    <span class="mt-1 font-semibold text-gray-600 text-xs uppercase">{{data.carrera_nombre}} </span>
                                    
                                </div>
                            </template>
                        </Column>
                        
                        <Column header="">
                            <template #body="{data}">
                                <div class="flex flex-row align-items-center">
                                    <span class="label label-dot mr-2" :class="'label-' + (data.estado == 'P' ? 'primary' :(data.estado == 'C' ? 'danger' : 'success'))"></span>
                                    <span class="font-semibold " :class="'text-' + (data.estado == 'P' ? 'blue-600' :(data.estado == 'C' ? 'danger-50' : 'success'))">{{StatusText(data.estado)}}</span>
                                </div>
                            </template>
                        </Column>
                        <Column header="Eval.">
                            <template #body="{data}">
                                <div class="flex flex-column align-items-center ">
                                    <i class="pi pi-users text-primary"/>
                                    <span class="mt-1 font-semibold text-gray-600 text-xs">{{data.evaluados}} de {{data.inscritos}} </span>
                                    
                                </div>
                            </template>
                        </Column>
                        <Column header="Visita">
                            <template #body="{data}">
                                <div class="flex flex-row align-items-center justify-content-between">
                                    <a href="javascript:;" class="text-primary font-bold text-gray-900">
                                        <span class="text-primary"><i class="pi pi-bookmark-fill"/> Visita {{data.visita}}</span><br>
                                        <span class="my-1 font-semibold text-sm text-gray-600">► {{DateTimeFormat(data.fecha_visita)}} </span>
                                    </a>
                                    <div class="flex">
                                        <Button icon="pi pi-arrow-right" class="p-button p-button-icon-only p-button-rounded p-button-text" :loading="loadingDetails" @click="upsertGuia_onClick(null,data)" v-tooltip.top="'Ver'"/>
                                    </div>
                                </div>
                                
                            </template>
                        </Column>
                        <template #footer>
                            <Paginator :rows="10" :totalRecords="paginationList.totalRecords" 
                              template="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                :rowsPerPageOptions="[10,20,50,100]" 
                                currentPageReportTemplate="Mostrando {first} - {last} de {totalRecords} guías" @page="onPage($event)">

                                <template #left>
                                    {{title}}<br>
                                </template>
                            </Paginator>
                        </template>
                    </DataTable>
                    <ProgramacionCalendario  :Values="guiaDocenteList" :key="calendarKey" v-if="CURRENT_VIEW == VIEW_TYPE.CALENDAR" class="mt-2" @onClickEvent="upsertGuia_onClick(null, $event)"/>
                </template>
            </Card>
        </div>
   </div>

   <GuiaDocenteUpsertDialog  :Service="guiaDocenteService" v-model:Upsert="upsertGuia" :key="upsertGuiaKey" :Init="initValue" @selectedType_onEmit="newAplicacion_onClick($event)" @update_onEmit="fx_onLoad()"/>
   <DocenteGrupoSearchDialog  :Service="guiaDocenteService" v-model:Searching="searchDocente" :key="searchDocenteKey" @create_onEmit="upsertGuia_onClick($event)" @report_onEmit="viewDocente_onClick($event)"/>
   <CarreraGrupoSearchDialog  :Service="guiaDocenteService" v-model:Searching="searchAsignatura" :key="searchAsignaturaKey"  @create_onEmit="upsertGuia_onClick($event)"/>
</template>

<script>
import FacultadService from '@/service/facultadService'
import GuiaDocenteService from '@/service/guiaDocenteService'
import GeneralService from '@/service/generalService'

import PerfilDocente from "@/components/LaborDocente/Guia/PerfilDocente.vue"
import GuiaDocenteUpsertDialog from "@/components/LaborDocente/Guia/GuiaDocenteUpsert.vue"
import DocenteGrupoSearchDialog from "@/components/LaborDocente/Guia/DocenteGrupoSearch.vue"
import CarreraGrupoSearchDialog from "@/components/LaborDocente/Guia/CarreraGrupoSearch.vue"
import ProgramacionCalendario from "@/components/LaborDocente/Guia/ProgramacionCalendar.vue"
import moment from 'moment'

const VIEW = {
    EMPLEADO : "empleado",
    LIST :  "lista general",
    GALLERY :  "asignatura",
    CALENDAR :  "programación"
}

let timer,
timeoutVal = 1000;

export default { 
    facultadService : null,
    guiaDocenteService : null,
    emits:[],
    props:{
       
    },
   
    data(){
        return {
            guiaDocenteList : [],
            initValue : null,
            
            facultadList : [],
            selectedFacultad : null,
            carreraList : [],
            selectedCarrera: null,
            cicloList: [],
            selectedCiclo: null,


            loadingCarrera: false,

            breadcrumbModel :[],
            VIEW_TYPE :  VIEW,
            CURRENT_VIEW : VIEW.LIST,
            upsertGuia : false,
            upsertGuiaKey : 0,

            searchDocente: false,
            searchDocenteKey: 0,
            searchAsignatura : false,
            searchAsignaturaKey : 0,
            calendarKey : 0,
            perfilDocenteKey : 0,
            itemsButtonUpsert : [],

            loadingData : false,
            loadingDetails : false,
            paginationList : { },
            title : "",
            searchTerm : "",
            statuses:[],
            selectedStatus : { code : "T"},
            CodEmpleado : 0
        }
    },
    created(){
        this.generalService =  new GeneralService(this.axios)
        this.breadcrumbModel = [ 
            { label: this.$store.state.uConfig.cfCurrentModule.mod_nombre, to: '#'},
            { label: "Revisión", to: '#'},
            { label: this.$store.state.uConfig.cfCurrentMenu.men_descripcion, to: '#'},
        ]



        this.itemsButtonUpsert = [
                {
                    label:'Buscar docente',
                    icon:'pi pi-pw pi-user text-primary',
                    command: () => {
                            this.searchDocente = true;
                            this.searchDocenteKey++
                        }
                },
                {
                    label:'Buscar Asignatura',
                    icon:'pi pi-pw pi-book text-primary',
                    command: () => {
                            this.searchAsignatura = true;
                            this.searchAsignaturaKey++
                        }
                },
        ]

        this.statuses =  [
            { name: 'Programado', code: 'P', icon:"pi-clock"}, 
            { name: 'Abierto', code: 'A', icon:"pi-sliders-h"}, 
            { name: "Cerrado", code: 'C', icon:"pi-check-square"}, 
            { name : "Todos", code : "T", icon: "pi-list"}
        ]
        
        this.facultadService =  new FacultadService(this.axios)
        this.guiaDocenteService =  new GuiaDocenteService(this.axios)
    },
    async mounted(){
        this.paginationList  = {
            totalRecords: 0,
            currentPage : 1,
            totalPages: 1,
            pageSize: 10,
        },
        await this.fx_init();
        await this.fx_onLoad(this.paginationList.currentPage, this.paginationList.pageSize, this.searchTerm)
    },
    methods:{
        toggle(event) { // OverlayPanel
            this.$refs.filterOP.toggle(event);
        },


        //#region aplicación filtro
        
        async fx_init(){
            try{
                await this.facultadService.getAllFacultad().then(data  => this.facultadList = data);
                await this.generalService.getCiclo().then(result => this.cicloList  =  result )
                //Buscar si pertenece especificamente a una facultad para limitar
                var fac  =  this.facultadList.filter(x=> x.fac_codigo == this.$store.state.uEmpleado.emp_codfac);

                // Si esta autorizado para ver todos y tiene asignada facultad reestringir facultad
                if(fac.length > 0 && this.$store.getters.evalAuth("VerTodos")){
                    this.selectedFacultad =  fac[0];
                    this.facultadList =  fac;
                    await this.selFacultad_onChange({ value :  { fac_codigo :  this.selectedFacultad.fac_codigo} })
                }else{
                    this.facultadList.push({fac_codigo: 0 , fac_nombre: "(Mis Asignaciones)" , fac_nombre_corto : "T"})
                    this.selectedFacultad =  this.facultadList[this.facultadList.length - 1];
                }

                //Selected Ciclo Vigente
                if(this.cicloList.length > 0 ){
                    var vigente =  this.cicloList.findIndex(x=> x.cil_vigente =="S");
                    this.selectedCiclo =  vigente <= -1 ? null : this.cicloList[vigente]
                }

                console.log(this.selectedCiclo)
                
            }catch(ex){
                this.$catchError(ex)
            }
        },

        async selFacultad_onChange(event){
            this.selectedCarrera = null;
            this.loadingCarrera = true;
            if(!event.value){
                return;
            }
            await this.facultadService.getCarreraByFacultad(event.value.fac_codigo).then(data => this.carreraList = data)
            this.loadingCarrera = false;
        },

        async apply_onClick(){ //Aplicar Filtro OverlayPanel
            this.$refs.filterOP.toggle();
             this.fx_onLoad(1, 10, "")
        },

        //#endregion Aplicación filtro 

        async fx_onLoad(page, pageSize, searchTerm){
            try{

                if(this.CURRENT_VIEW == this.VIEW_TYPE.EMPLEADO){
                    this.perfilDocenteKey++
                    return
                }


                this.loadingData =  true;
                this.title = this.Facultad ? this.Facultad.fac_nombre : "TODAS FACULTADES "
                this.title += " >> " +(this.Carrera ? this.Carrera.car_nombre : "TODAS LAS CARRERAS")

                var fac_codigo  = this.selectedFacultad == null ? -1 : this.selectedFacultad.fac_codigo
                var car_codigo  = this.selectedCarrera == null ? 0 : this.selectedCarrera.car_codigo
                await this.guiaDocenteService.getAllDesempenoDocente(
                {
                    page : page,
                    pageSize : pageSize,
                    fac_codigo :  fac_codigo,
                    car_codigo :  car_codigo,
                    ddo_estado :   this.selectedStatus.code,
                    searchTerm :  searchTerm,
                    cil_descripcion : this.selectedCiclo.cil_descripcion
                }).then(result =>{
                    this.paginationList =  {
                        totalRecords: result.totalRecords,
                        currentPage : result.currentPage,
                        totalPages: result.totalPages,
                        pageSize: result.pageSize,
                    }
                    this.guiaDocenteList =  result.data
                });
            }
            catch(ex){
                this.$catchError(ex)
            }finally{
                 this.loadingData =  false;
            }
        },

        viewMode_onClick(mode){
            
            if(this.CURRENT_VIEW == this.VIEW_TYPE.CALENDAR){
                this.calendarKey ++
                this.CURRENT_VIEW = mode
            }
            else if(mode== this.VIEW_TYPE.EMPLEADO){
                this.searchDocente = true;
                this.searchDocenteKey++
            }  else{
                this.CURRENT_VIEW = mode
            }

        },
        //#region Controls
        newAplicacion_onClick(evt){
            if(evt=='D')
                this.itemsButtonUpsert[0].command()
            else if(evt== 'C')
                 this.itemsButtonUpsert[1].command()
        },
        /**
        * @param {Object} evt  evento de entrada resultado de emits => default : null
        */
        async upsertGuia_onClick(evt = null, data = null){
            /*
            The 'initValue => Init' property has the patron:
                Init : 
                    asignatura : object
                    facultad : object
                    carrera : object
                    plan : object
                    asignatura : object
                    empleado : object
                    ciclo: object
            */
            this.initValue = null
            var dataValue =null
            if(evt){
                //for event selected origin
                dataValue = {}
                dataValue = {
                    ddo_codigo: 0,
                    ddo_codpld: 0,
                    ddo_codemp: evt.empleado.emp_codigo,
                    ddo_lugar:  "",
                    ddo_codhor: evt.hor_codigo,
                    ddo_hor_descripcion:  evt.hor_descripcion,
                    
                    ddo_tecnica: "",
                    ddo_saberes: "",

                    ddo_fecha_visita: new Date(),
                    ddo_est_presentes: 0,
                    ddo_est_inscritos: evt.hor_total_ins,
                    ddo_codreg: evt.ciclo.cil_codreg,
                    ddo_codcil: evt.ciclo.cil_codigo,
                    ddo_num_visita: 0,
                    ddo_estado:  null
                }
                dataValue["facultad"] =  evt.facultad
                dataValue["carrera"] =  evt.carrera
                dataValue["plan"] =  evt.plan
                dataValue["empleado"] = evt.empleado;
                dataValue["asignatura"] = evt.asignatura
            }else if(data){
                this.loadingDetails = true
                //for edit
                dataValue = {}
                dataValue =  await this.guiaDocenteService.getDesempenoDocenteById(data.codigo);
                dataValue.ddo_fecha_visita =  new Date(dataValue.ddo_fecha_visita)

                this.loadingDetails =  false
            }

            this.initValue = dataValue;
            this.upsertGuia =  true;
            this.upsertGuiaKey++;
        },
        
        viewDocente_onClick(codigo)
        {
            this.CodEmpleado =  codigo
            this.CURRENT_VIEW =  this.VIEW_TYPE.EMPLEADO
        },
        //#endregion Controls

        async onPage(event) {
            //event.page: New page number
            //event.first: Index of first record
            //event.rows: Number of rows to display in new page
            //event.pageCount: Total number of pages
            await this.fx_onLoad(++event.page, event.rows,null);
        },
        // eslint-disable-next-line no-unused-vars
        handleKeyPress(e) {
            window.clearTimeout(timer);
        },
        // eslint-disable-next-line no-unused-vars
        handleKeyUp(e) {
            this.loadingData =  true;
            window.clearTimeout(timer); 
            timer = window.setTimeout(async () => {
                await this.fx_onLoad(1, this.paginationList.pageSize,this.searchTerm);
               this.loadingData =  false;

            }, timeoutVal);

        },
        DateTimeFormat(data) {
            return moment(data).format('DD/MM/YYYY, h:mm a')
        },
         
        StatusText(estado){
            if(estado == 'P') return "Programado";
            else if(estado == 'C') return "Cerrado"
            else return "Abierto"
        },
       
    },
    watch: {
        //eslint-disable-next-line no-unused-vars
        selectedStatus:async function (newValue, oldValue){
            await this.fx_onLoad(1, this.paginationList.pageSize, this.searchTerm)
            if(this.VIEW_TYPE.CALENDAR)
                this.calendarKey++
        }
    },
    computed:{
        IsViewDetails(){
            var show = this.CURRENT_VIEW == this.VIEW_TYPE.LIST || this.CURRENT_VIEW == this.VIEW_TYPE.CALENDAR
            
            return show
        }
    },
    components:{
        GuiaDocenteUpsertDialog,
        DocenteGrupoSearchDialog,
        CarreraGrupoSearchDialog,
        ProgramacionCalendario,
        PerfilDocente
    }
}
</script>
<style lang="scss" >
    .p-card .p-card-content {
       padding-top:0px;
   }


    
</style>
<style lang="scss" scoped>
  
::v-deep(.p-paginator) {
    .p-paginator-current {
        margin-left: auto;
    }
}
</style>

