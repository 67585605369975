<template>
    <Dialog v-model:visible="isModalVisible" :style="{width: '640px'}" :modal="true" class="p-fluid" @hide= "$emit('hide')" :closeOnEscape="false">
        <template #header>
            <div class="flex flex-column align-items-center w-full">
                <h4 class="font-semibold text-gray-900 mb-0 pb-0">Grupo por Docente</h4>
                <small  class="text-muted  font-normal ">Grupos de asignatura autorizadas a docente</small>
            </div>
        </template>
        <Divider />
        <div class="flex ">
            <Dropdown  id="cil_codigo"  v-model="selectedCiclo" :options="cicloList" placeholder="Seleccione Ciclo">
                <template #empty>
                    Opciones no válida
                </template>
                <template #value="slotProps">
                    <div  v-if="slotProps.value">
                        <span>{{slotProps.value.cil_descripcion}}</span>
                    </div>
                    <span v-else>
                        {{slotProps.placeholder}}
                    </span>
                </template>
                <template #option="slotProps">
                    <span>{{slotProps.option.cil_descripcion}}</span>
                </template> 
            </Dropdown>
            <Button type="button"   :label="!resultEmpleado ? 'Buscar Docente' : resultEmpleado.emp_nombres_apellidos"  
                                    :icon="!resultEmpleado ? 'pi pi-filter' : 'pi pi-user'" 
                                    class="w-full mx-1 p-button-info btn-light-primary mr-2"  @click="searchEmpleado_onClick()"/> 
        </div>
       
        <div class="flex my-2">
            <DataTable :value="docenteGrupoList" responsiveLayout="scroll" :rowHover="true" class="p-datatable-sm w-full" :loading="loadingData">
                <template #loading>
                    Cargando grupos, por favor espere...
                </template>
                <template #empty>
                    No hay grupos para este docente.
                </template>
                <Column header="Grupos">
                    <template #body="{data}">
                       <div class="flex align-items-center">
                            <div class="symbol symbol-40 symbol-circle symbol-light-primary shadow-3">
                                <span class="symbol-label font-size-h4">{{data.hor_descripcion[0].toUpperCase()}}</span>
                            </div>
                            <div class="flex flex-column ml-3">
                                <a href="javascript:;" class="text-primary font-bold text-gray-900">{{data.hor_descripcion}}</a>
                                <span class="mt-1 font-normal text-gray-600 text-sm">{{data.asignatura.plm_mat_nombre}} </span>
                                <span class="mt-1 font-normal text-gray-600 text-xs">{{data.facultad.fac_nombre}}</span>
                            </div>
                        </div>
                    </template>
                </Column>
                <Column headerStyle="width:130px">
                    <template #body="{data}">
                        <div class="flex justify-content-end flex-shrink-0">
                            <a href="javascript:void(0)" class="btn btn-bg-light p-2 text-primary" @click="createGuia_onClick(data)"> 
                                <i class="pi pi-chevron-right"></i> Generar
                            </a>
                        </div>
                    </template>
                </Column>
            </DataTable>
        </div>

        
        <template #footer class="">
            <Divider/>
            <div class="flex mt-5">
                <div class="block font-bold text-left">
                    <a href="javascript:;" class="text-gray-900 hover:text-primary" @click="viewDetails_onClick()">Ir a informe de este docente</a>
                    <div class="text-sm text-gray-400">El detalle muestra listado de asignaturas autorizadas, visitas realizadas e informes de desempeño. </div>
                </div>
            </div>
        </template> 
    </Dialog>


     <!--Begin : Empleado Dialog -->
    <Dialog v-model:visible="isDialogEmpleado" :breakpoints="{'1360px': '95vw'}" :style="{width: '50vw'}" :modal="true">
        <template #header>
            <h5 class="mb-3 font-bold">Buscar Empleado</h5>
        </template>
        <EmpleadoDataTable  v-model:selectedItems="resultEmpleado" :Mode="'single'"> </EmpleadoDataTable>
        <template #footer>
            <Button label="Cancelar" icon="pi pi-times"  class="p-button-text" @click="resultEmpleado = null; isDialogEmpleado = false"/>
            <Button label="Aceptar" icon="pi pi-check" class=""  @click="selectedEmpleado_onClick()"  />
        </template>
    </Dialog>
</template>

<script>
import EmpleadoDataTable from "../../Generales/EmpleadoDt"
import EmpleadoService from '@/service/empleadoService'
import GeneralService from '@/service/generalService'

export default {
    emits:["hide", "create_onEmit", "report_onEmit"],
    empleadoService : null,
    generalService :  null,
    props:{
        Service: null,
        Searching: {
            type: Boolean,
            default: false
        },
        
    },
    data(){
        return {
            docenteGrupoList : [],
            resultEmpleado : null, 
            cicloList : [],
            selectedCiclo : null,
            isModalVisible :  this.Searching,
            isDialogEmpleado: false,
            loadingData : false
        }
    },
    created(){
         this.empleadoService =  new EmpleadoService(this.axios)
         this.generalService =  new GeneralService(this.axios)
    },
    async mounted(){
         await this.generalService.getCiclo().then(result => this.cicloList  =  result )

   },
    methods:{
        viewDetails_onClick(){
            if(!this.resultEmpleado){
                 this.$toast.add({severity:'warn', summary:'¡Empleado!', detail:"Seleccione un empleado válido.", life: 3000});
                 return;
            }
            this.$emit('report_onEmit', this.resultEmpleado.emp_codigo)

            this.isModalVisible = false
        },
        async searchEmpleado_onClick(){
            if(!this.selectedCiclo){
                 this.$toast.add({severity:'warn', summary:'¡Ciclo!', detail:"Seleccione un ciclo", life: 3000});
                 return;
            }
            this.isDialogEmpleado = true
        },
        async selectedEmpleado_onClick(){
            this.isDialogEmpleado =  false;
            this.loadingData =  true
            this.docenteGrupoList =  await this.Service.getGrupoByDocente(this.selectedCiclo.cil_codigo, this.resultEmpleado.emp_codigo);
            this.loadingData = false
        },

        async createGuia_onClick(data){
            var resultData =  {...data}
            //Empleado property
            resultData["empleado"] =   resultData["empleado"] =  await this.empleadoService.GetById(data.empleado.emp_codigo)
            this.$emit("create_onEmit",resultData)
            this.isModalVisible =  false;
        }
    },
    computed:{},
    components:{
        EmpleadoDataTable
    }
}
</script>