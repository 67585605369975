<template>
    <div>
        <FullCalendar :events="events" :options="options" ref="cal" />
    </div>
</template>

<script>
import gridPlugin from '@fullcalendar/daygrid';
import listPlugin from '@fullcalendar/list';
import interactionPlugin from '@fullcalendar/interaction';
export default {
    emits:["onClickEvent"],
    props: {
        Values :{
            type: Array,
            default(){
                return []
            }
        }
    },
    data(){
        return{
            options: {
                locale: "es",
                plugins:[interactionPlugin,gridPlugin,listPlugin],
                initialView: 'listWeek',
                headerToolbar: {
                    left: 'prev,next today',
                    center: 'title',
                    right: 'listWeek,dayGridMonth'
                },
                buttonText:{
                    today : "Hoy",
                    list: "Lista Semanal",
                    month: "Mes"
                },
                editable: true,
                selectable:true, 
                selectMirror: true, 
                dayMaxEvents: true,
                eventDidMount: (eventInfo) => {
                    // Set the dbclick event
                    eventInfo.el.onclick = () =>{
                        this.$emit("onClickEvent", eventInfo.event._def.extendedProps)
                    };
                }
            },
            events :null,
        }
    },
    created(){
        this.events = this.Values.map((item)=>{
            return {
                id: item.codigo,
                title: item.horario_descripcion + "\n " + item.materia_nombre,
                start:item.fecha_visita,
                extendedProps: item
            }
        })

        
    },
    methods:{
    }
}
</script>

<style scoped>
@media screen and (max-width: 960px) {
    ::v-deep(.fc-header-toolbar) {
        display: flex;
        flex-wrap: wrap;
    }
}

</style>
<style>

.fc-event-title{
    color: #000;
    word-break: break-all;
    white-space: pre-wrap;
    white-space: -moz-pre-wrap;
}
</style>