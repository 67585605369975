<template>
    <div class="mx-auto container">
        <!-- Enlace Tab -->
        <div class="flex mb-3 mx-4">
            <a href="javascript:;" class="pb-1  border-bottom-2 text-gray-500 font-bold text-xl hover:text-primary" :class="classTabView(VIEW.HISTORIAL)" @click="viewHistorial_onClick()">Historial</a>
            <a href="javascript:;" class="pb-1 mx-4 border-bottom-2 text-gray-500 font-bold text-xl hover:text-primary" :class="classTabView(VIEW.RESULTADOS)">Resultados</a>
            <a href="javascript:;" class="pb-1 border-bottom-2 text-gray-500 font-bold text-xl hover:text-primary" :class="classTabView(VIEW.INFORMES)">Informes</a>
        </div>
        <div class="grid ">
             <!-- INFORME DE RESULTADOS -->
            <div class="col-12" v-if="CURRENT_TAB == VIEW.INFORMES">
                <div class="grid ">
                    <!--Begin:: Areas | secciones de evaluacion  -->
                    <div class="col-12 md:col-4">
                        <div class="h-full">
                            <div class="shadow-2 p-3 h-full flex flex-column surface-card" style="border-radius: 6px">
                                <div class="flex justify-content-between  flex-wrap flex-column md:flex-row" >
                                    <div class="flex flex-column mb-3" >
                                        <h5 class="mb-0  text-gray-900 font-bold text-base sm:text-lg">Secciones/areas del Informe</h5>
                                        <small  class="text-gray-400 font-semibold text-sm">Las secciones o áreas de desempeño segun esquema de evaluación</small>
                                    </div>
                                    <!-- Action -->
                                    <div class="flex align-items-end" >
                                    </div>
                                </div>
                                <!--Begin:: Lista de areas de la evaluacion -->
                                <div class="wizard-nav">
                                    <div class="wizard-steps" >
                                        <div class="wizard-step cursor-pointer" >
                                            <!--Secundarias:: Areas | Secciones -->
                                            <div class="wizard-step py-0"  v-for="(areaSeccion,index) in seccionGroupComputed" :key="index" 
                                                        :data-wizard-state="selectedSeccionList.code == areaSeccion.code ? 'current' : 'pending'"
                                            @click="selectedArea_onClick(areaSeccion.code);"> 
                                                <div class="wizard-wrapper">
                                                    <div class="wizard-icon">
                                                        <ChartBar2Ico  :size="'svg-icon svg-icon-2tx'" :color="''" v-if="areaSeccion.code == 0"/>
                                                        <LayouTopPanelIco  :size="'svg-icon svg-icon-2tx'" :color="''" v-else/>
                                                    </div>
                                                    <div class="wizard-label">
                                                        <h3 class="wizard-title my-0 uppercase" >{{areaSeccion.groupName}}  </h3>
                                                    </div>                                           
                                                </div>
                                            </div>
                                        </div>
                                    </div>     
                                </div>
                                <!--End:: Lista de areas de la evaluacion -->
                                <div class="h-3px w-100 bg-primary p-0 m-0 mb-3"/>
                                <div class="flex flex-column" v-if="desempenoInforme.ddi_estado == 'E'">
                                    
                                    <div class="p-fluid w-full">
                                        <div class="field">
                                            <label class="font-bold">Compromisos adquiridos</label>
                                            <Textarea  v-model="desempenoInforme.ddi_compromiso" rows="3"  :autoResize="true"   placeholder="Según los logros obtenidos y aspectos a mejorar" />
                                            <div class="flex justify-content-end" :class="{'text-danger': (desempenoInforme.ddi_compromiso.length > 2500)}">
                                                <small>{{desempenoInforme.ddi_compromiso.length}}/2500</small>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="flex flex-row justify-content-end">
                                        <Button label="Actualizar" icon="" class="ml-1 p-button p-button-info  " :loading="loadingUpdate" @click="update_onClick()" v-tooltip.top="'Imprimir'"/>
                                    </div>
                                </div>
                                <div class="flex flex-column align-items-center" v-else> 
                                    <label class="font-bold">Compromisos adquiridos</label>
                                    <span  class="font-semibold text-gray-500">{{desempenoInforme.ddi_compromiso}}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!--Begin:: Ver y Organizar resultados | Comentarios -->
                    <div class="col-12 md:col-8">
                        <div class="grid">
                            <!-- Header -->
                            <div class="col-12">
                                <div class="shadow-2 p-3 h-full flex flex-column surface-card" style="border-radius: 6px">
                                     <!--Header-->
                                    <div class="flex justify-content-between  flex-wrap flex-column md:flex-row" >
                                        <div class="flex flex-column mb-2" >
                                            <h5 class="mb-0  text-gray-900 font-bold text-base sm:text-lg">{{selectedSeccionList.groupName}}</h5>
                                            <small  class="text-gray-400 font-semibold text-sm">Organice los comentarios para la sección de los diferentes instrumentos de evaluación</small>
                                        </div>
                                    </div>

                                    <AlertMessage :resource="'INFORME'" v-model:type="statusClass">
                                        <template #buttons>
                                        <a v-if="desempenoInforme.ddi_estado != 'P'" href="javascript:void(0)" :class="'p-button-'+statusClass" class=" p-button font-bold ml-1  align-self-center" 
                                        @click="update_onClick(desempenoInforme.ddi_estado == 'E' ? 'A' : 'P')"> 
                                                <i class="pi pi-checkb pi-pw mr-1" /> {{desempenoInforme.ddi_estado =="E" ? "Validar" : "Autorizar"}}</a>

                                        <Button v-else label="Imprimir" icon="" class="ml-1 p-button py-0" :class="'p-button-'+statusClass" :loading="loadingPrint" @click="print_onClick()" v-tooltip.top="'Imprimir'"/>
                                        </template>
                                    </AlertMessage>
                                </div>
                            </div>
                            
                            <!--Resumen | Detalle | Comentarios -->
                            <div class="col-12">
                                <!-- Resumen: Gráficos-->
                                <!-- Detalle | Comentarios -->
                                <TabView ref="tabview1">
                                    <TabPanel>
                                        <template #header>
                                            <i class="pi pi-chart-pie mr-2"></i> 
                                            <span>Resultados</span>
                                        </template>
                                         <div class="grid">
                                            <div class="col-12 lg:col-6" v-for="data, index in selectedSeccionList.results" :key="index">
                                                <div class="h-full flex flex-column justify-content-between">
                                                    <div class="flex flex-column">
                                                        <h5 class="font-semibold text-lg">
                                                            <span>{{index+1}}. </span> 
                                                            <span v-if="!selectedSeccionList.isResumen">{{data.rce_enunciado}}</span>
                                                            <span v-else class="uppercase">{{data.rse_nombre}}</span>
                                                        </h5>
                                                    </div>
                                                    <div class="block align-items-end">
                                                        <ApexCharts  type="pie" :options="fx_chartResultOptions(data.escalaList)" :series="fx_chartResultSeries(data.escalaList)"></ApexCharts>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </TabPanel>
                                    <TabPanel >
                                        <template #header>
                                            <i class="pi pi-comments mr-2"></i>
                                             <span>Observaciones</span>
                                        </template>
                                            <div class="flex justify-content-between align-items-center mb-3">
                                                <div class="field fluid mb-0">
                                                    <div class="field-checkbox mb-0">
                                                        <label class="mr-2 ">Omitidos</label>   
                                                        <InputSwitch v-model="mostrarOmitido" />    
                                                    </div>
                                                </div>
                                                

                                                <Button  class="p-button-sm btn btn-bg-light text-gray-800 " 
                                                     @click="commentsToggle" aria-haspopup="true" aria-controls="overlay_commentsMenu">
                                                    <i class="pi pi-chart-line"></i> 
                                                    <span class="mx-2">{{commentsItems.find(x=> x.clicked).label}}</span>
                                                    <i class="pi pi-chevron-down"></i> 
                                                </Button>

                                                <OverlayPanel ref="commentsMenu" :breakpoints="{'960px': '75vw', '640px': '100vw'}" :style="{width: '320px'}" class="p-0" id="overlay_commentsMenu">
                                                    <div class="flex  flex-column">
                                                        <div  class="flex flex-shrink-0  " v-for="items in commentsItems" :key="items">
                                                            <a href="javascript:;" class="btn border-round  w-full hover:bg-blue-50 text-gray-700 flex  align-items-center" @click="commentsToggle_onSelected(items)"> 
                                                                <LayoutGridIco class="mr-1 flex align-items-center"/> 
                                                                <span class=" font-bold ">{{items.label}}</span>
                                                            </a>
                                                        </div>
                                                    </div>
                                                </OverlayPanel>
                                            </div>

                                            <DataTable :value="selectedComentariosList" :reorderableColumns="true" responsiveLayout="scroll" @row-reorder="onRowReorder" selectionMode="single">
                                                <template #empty>
                                                    <p  class="font-italic text-center">No hay comentarios que mostrar.</p>
                                                </template>
                                                <Column :rowReorder="desempenoInforme.ddi_estado == 'E'" v-if="desempenoInforme.ddi_estado == 'E'" headerStyle="width: 2rem" :reorderableColumn="false" >
                                                    <template  #body="{}">
                                                        <i class="p-datatable-reorderablerow-handle pi pi-bars"></i>
                                                    </template>
                                                </Column>
                                                <Column field="" :header="selectedCommentsItem.label" >
                                                    <template #body="{data, index}"> 
                                                        <div class="flex">
                                                            <div class="mr-3">
                                                                <div class="symbol symbol-20 symbol-circle shadow-3" :class="data.dir_omitido ? 'symbol-light-danger' : 'symbol-light-primary'">
                                                                    <span class="symbol-label font-size-h4">
                                                                        <i :class="'pi pi-' + (data.dir_inst_origen == 'INS_EEDD' ? 'users' : 'user-edit') "/></span>
                                                                </div>
                                                            </div>
                                                            <span class=" text-base">{{data.dir_observacion}}</span>
                                                            <span class="ml-2 font-semibold text-xs">({{index +1}})</span>
                                                        </div>
                                                    </template>
                                                </Column>
                                                <Column field="" header="" headerClass="w-4rem">
                                                    <template #body="{data,index}"> 
                                                        <Button :icon="'pi pi-' + (data.dir_omitido ? 'eye' : 'eye-slash')" class="p-button p-button-icon-only p-button-rounded p-button-text"  v-tooltip.top="'Ocultar'" @click="omitDesempenoInforme(index,data)"/>
                                                    </template>
                                                </Column>
                                            </DataTable>
                                    </TabPanel>
                                </TabView>
                            </div>
                        </div>
                    </div>
                    <!--Begin:: Ver y Organizar resultados | Comentarios -->
                </div>
            </div>
            
            <!-- header info -->
            <div class="col-12 md:col-5 lg:col-4" v-if="CURRENT_TAB == VIEW.HISTORIAL || CURRENT_TAB == VIEW.RESULTADOS">
                 <CardEmpleadoInfo :Code="Codigo" @selectedCiclo_onEmit="selectedCiclo = $event" @selectedGrupo_onEmit="selectedMateria_onClick($event)"/>
            </div>

            <!-- HISTORIAL / RESULTADOS -->
            <div class="col-12 md:col-7 lg:col-8">
                <!-- Historial de visitas visitas -->
                <div class="p-card px-4" v-if="CURRENT_TAB == VIEW.HISTORIAL">
                    <div class="p-card-title">
                        <div class="card-header">
                            <div class="card-title">
                                <div class="flex flex-column">
                                    <h5 class="ml-3 my-0 font-semibold text-gray-900 text-lg flex align-items-center">Historial de visitas de desempeño</h5>
                                    <div class="text-gray-500 text-sm font-semibold mx-3">Visitas correspondientes al ciclo <span class="text-primary">vigente</span> </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="flex flex-column mt-5" >
                        <DataTable :value="desempenoDocenteList" stripedRows :rowHover="true" :rows="30" responsiveLayout="scroll"  :loading="loadingData"
                            :rowsPerPageOptions="[30,50,100]"  class="p-datatable-sm">
                            <template #loading>
                                <p  class="font-italic text-center">Espere mientras encontramos los datos...</p>
                            </template>
                            <template #empty>
                                <p  class="font-italic text-center">No hay visitas de desempeño que mostrar</p>
                            </template>
                            <Column header="Docente">
                                <template #body="{data}">
                                    <div class="flex align-items-center">
                                    <div class="symbol symbol-45 symbol-light mr-4" >
                                                <span class="symbol-label">
                                                    <LayoutGridIco  :size="'svg-icon-2x'"/>
                                                </span>
                                            </div>
                                        <div class="flex flex-column ml-3">
                                            <a href="javascript:;" class="text-primary font-bold text-gray-900" >{{data.materia_nombre}}</a>
                                            <a href="javascript:;" class=" font-bold text-gray-600 text-sm" ><span class="uppercase">{{data.plan_acad_nombre}}</span></a>
                                            <a href="javascript:;" class=" font-bold text-gray-800 text-sm">{{data.horario_descripcion}}</a>
                                            <span class="mt-1 font-semibold text-gray-600 text-sm uppercase">{{data.carrera_nombre}}</span>
                                        </div>
                                    </div>
                                </template>
                            </Column>
                           
                            <Column header="">
                                <template #body="{data}">
                                    <div class="flex flex-row align-items-center">
                                        <span class="label label-dot mr-2" :class="'label-' + (data.estado == 'P' ? 'primary' :(data.estado == 'C' ? 'danger' : 'success'))"></span>
                                        <span class="font-semibold " :class="'text-' + (data.estado == 'P' ? 'blue-600' :(data.estado == 'C' ? 'danger-50' : 'success'))">{{StatusText(data.estado)}}</span>
                                    </div>
                                </template>
                            </Column>
                            <Column header="Eval.">
                                <template #body="{data}">
                                    <div class="flex flex-column align-items-center ">
                                        <i class="pi pi-users text-primary"/>
                                        <span class="mt-1 font-semibold text-gray-600 text-xs">{{data.evaluados}} de {{data.inscritos}} </span>
                                        
                                    </div>
                                </template>
                            </Column>
                            <Column header="Visita">
                                <template #body="{data}">
                                    <div class="flex flex-row align-items-center justify-content-between">
                                        <a href="javascript:;" class="text-primary font-bold text-gray-900">
                                            <span class="text-primary"><i class="pi pi-bookmark-fill"/> Visita {{data.visita}}</span><br>
                                            <span class="font-semibold text-sm   text-gray-600"><i class="pi pi-calendar"/> {{DateTimeFormat(data.fecha_visita)}}</span>
                                        </a>
                                        <div class="flex">
                                            <Button icon="pi pi-arrow-right" class="p-button p-button-icon-only p-button-rounded p-button-text" :loading="loadingDetails" @click="upsertGuia_onClick(null, data)" v-tooltip.top="'Ver'"/>
                                        </div>
                                    </div>
                                    
                                </template>
                            </Column>
                            
                        </DataTable>
                    </div>
                </div>
                <!-- Resultados por Asignatura -->
                <div class="mb-1 " v-if="CURRENT_TAB == VIEW.RESULTADOS">
                    <div class="grid ">
                        <!-- Lista de Visitas -->
                        <div class="col-12 md:col-6">
                             <div class="px-3 h-full">
                                <div class="shadow-2 p-3 h-full flex flex-column surface-card" style="border-radius: 6px">
                                    <div class="flex justify-content-between  flex-wrap flex-column md:flex-row" >
                                        <div class="flex flex-column mb-3" >
                                            <h5 class="mb-0  text-gray-900 font-bold text-base sm:text-lg">Visitas aplicadas</h5>
                                            <small  class="text-gray-400 font-semibold text-sm">Administrando  {{desempenoDocenteFilter.length}} aplicaciones</small>
                                        </div>
                                        <!-- Action -->
                                        <div class="flex align-items-start" >
                                            <a href="javascript:;"  v-tooltip.top="'Ayuda'">
                                                <BulbIco :color="'svg-icon-warning'"/> 
                                            </a>
                                        </div>
                                    </div>
                                    <ul class="list-none p-0 m-0 flex-grow-1" v-if="desempenoDocenteFilter.length > 0" >
                                        <li class="flex flex-column align-items-center" v-for="list in desempenoDocenteFilter" :key="list">
                                            <div class="flex justify-content-between w-full" >
                                                <div class="flex">
                                                    <div class="h-100 p-0 mr-2 border-round" :class="'bg-' + (list.estado == 'P' ? 'primary' :(list.estado == 'C' ? 'danger' : 'success'))" style="width: 5px;"></div>
                                                    <div class="flex flex-grow-1 flex-column justify-content-center">
                                                        <div class="text-gray-800 font-bold">Visita No. {{list.visita}} </div>
                                                        <span class="font-semibold text-sm">{{DateTimeFormat(list.fecha_visita,'t')}} ,  {{DateTimeFormat(list.fecha_visita,'d')}}</span>
                                                        <small class="mt-1 font-semibold text-gray-700 text-xs">{{list.evaluados}} evaluados/ {{list.inscritos}} inscritos </small>
                                                      
                                                    </div>
                                                </div>
                                                <div class="">
                                                    <Button v-if="!list.informe" icon="pi pi-sync" class="p-button p-button-icon-only p-button-rounded p-button-text" :loading="loadingDetails" v-tooltip.top="'Sincronizar informe'" @click="addDesempenoToInforme(list)"/>
                                                    <Button icon="pi pi-external-link" class="p-button p-button-icon-only p-button-rounded p-button-text" :loading="loadingDetails" @click="upsertGuia_onClick(null,list)" v-tooltip.top="'Ver'"/>
                                                </div>
                                            </div>
                                            <Divider type="dashed" class="p-divider-muted" />
                                        </li>
                                    </ul>
                                    <div class="flex-grow-1" v-else >
                                        <h2 class="font-medium text-gray-800 text-center">Sin datos 
                                            <br>¿Crear 
                                            <span class="font-bold">Visita de Desempeño?</span>
                                        </h2>
                                    
                                    </div>
                                    <hr class=" mx-0 border-top-1 border-none surface-border"/>
                                      <small  class="text-gray-400 font-semibold text-sm">{{selectedDocenteGrupo.asignatura.plm_mat_nombre}}</small>
                                      <small  class=" mb-3 text-gray-400 font-semibold text-sm">{{selectedDocenteGrupo.carrera.car_nombre}}</small>
                                    <Button label="Crear Visita" icon="" class="p-button p-button-info btn-light-primary w-full" :loading="loadingDetails" @click="upsertGuia_onClick(selectedDocenteGrupo,null)" v-tooltip.top="'Ver'"/>
                                </div>
                            </div>
                        </div>
                        <!-- Card : Resultado del Informe -->
                        <div class="col-12 md:col-6">
                            <div class="px-3 h-full">
                                <div class="shadow-2 p-3 h-full flex flex-column surface-card" style="border-radius: 6px">
                                    <div class="flex justify-content-between  flex-wrap flex-column md:flex-row" >
                                        <div class="flex flex-column mb-3" >
                                            <h5 class="mb-0  text-gray-900 font-bold text-base sm:text-lg">Informe Desempeño Docente</h5>
                                            <small  class="text-gray-400 font-semibold text-sm"></small>
                                        </div>
                                        <div class="flex align-items-end" >
                                        </div>
                                    </div>
                                    <div class="flex-grow-1 py-0 my-0" >
                                        <div class="" style=";">
                                            <ApexCharts   type="donut" :options="chartOptions" :series="chartSeries" ></ApexCharts>
                                        </div>
                                    </div>
                                    <div class=" w-full">
                                        <p class="text-center font-normal text-base pb-1">Nota: El informe final es la recopilación de las visitas <br>para tener un análisis completo.</p>
                                        <div class="flex">
                                            <Button label="Ver Informe" icon="" class="p-button  w-full mr-1" :loading="loadingDetails" @click="generateInforme_onClick()" v-tooltip.top="'Ver'"/>
                                            <Button label="Imprimir" icon="" class="ml-1 p-button p-button-info  w-full" :loading="loadingPrint" @click="print_onClick()" v-tooltip.top="'Imprimir'"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 md:col-6 " v-for="data in rendimientoAcademicoList" :key="data">
                            <div class="px-3 h-full">
                                <div class="shadow-2 p-3 h-full flex flex-column surface-card widget-box " :class="'wb-'+statusRendClass(data.rai_estado)" style="border-radius: 6px">
                                    <div class="flex justify-content-between flex-wrap ">
                                        <div class="flex align-items-center">
                                            <div class="flex flex-column">
                                                <a class="font-bold text-gray-700 hover:text-primary uppercase">{{data.rai_deschor}}</a>
                                                <div class="text-muted font-semibold">{{data.asignatura.plm_mat_nombre}}</div>
                                                <div class="text-muted font-semibold">{{data.carrera.car_nombre}}</div>
                                            </div>
                                        </div>
                                        <div class="flex">
                                            <div class="label label-lg label-inline font-semibold"
                                            :class="'label-light-' + statusRendClass(data.rai_estado)">
                                                <i class="pi pi-star inline-flex md:hidden"></i>
                                                <span class="hidden md:inline-flex">{{data.rai_estado === 'A' ? 'Abierto' :  'Enviado' }} </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="text-gray-400 font-bold text-sm">Informe 
                                        <!--begin::Name-->
                                        <a href="javascript:;" class="text-primary hover:opacity-75 font-bold">Cómputo {{data.rai_computo}}</a>
                                        <!--end::Name-->
                                    </div>
                                    <div class="flex flex-column mt-3" >
                                        <Button label="Imprimir" :loading="loadingPrintRendimiento" icon="pi pi-print" 
                                        class="p-button-outlined p-button-sm h-full" :class="'p-button-'+statusRendClass(data.rai_estado)" @click="printInforme_onClick(data)"  />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>          
                </div>
            </div>
        </div>
    </div>

    <PrintDialog :Title="titlePrint" v-model:Visible="isPrintDialog"  :BLOB="printBlob"  v-if="isPrintDialog" />
</template>

<script>

import RendimientoAcademicoService from '@/service/rendimientoAcademicoService'
//Components
import {LayoutGrid, LayouTopPanel, ChartBar2, Bulb} from "@/components/svg/_svgHelper.js"
import AlertMessage from "@/components/cards/AlertMessage"
import CardEmpleadoInfo  from "@/components/Integracion/CardEmpleadoInfo"
//Services
import GuiaDocenteService from '@/service/guiaDocenteService'
import GeneralService from '@/service/generalService'
//Libraries
import moment from 'moment'
import methods from "@/utilities/generalMethods.js"

import VueApexCharts from 'vue3-apexcharts'
import PrintDialog from '@/components/Generales/PrintDialog.vue'

//const delay = ms => new Promise(res => setTimeout(res, ms)); 

const TABVIEW = {
    HISTORIAL : "Historial",
    INFORMES : "Informes",
    RESULTADOS : "Resultados",
}

/**  */
export default {
    guiaDocenteService : null,
    rendimientoAcademicoService: null,
    generalService : null,
    emits:["view_onEmit"],
    props: {
        Codigo:{
            type : Number,
            default: 0
        }
    },
    data(){
        return{
            codigo : this.Codigo, // empleado
            isGenerate: false, 
            rendimientoAcademicoList : [],
            docenteGrupoList : [],
            selectedDocenteGrupo : null,
            desempenoDocenteList: [],
            
            dataInforme:{
                revisionList: [],
                comentarioList: [],
                seccionList : []
            },
            desempenoInforme:{
                ddi_codigo :  0,
                ddi_compromiso :  "",
            },

            /*Begin: Control */
            selectedSeccionList: [],
            selectedComentariosList : [],
            commentsItems:[],
            selectedCommentsItem :  {label: ""},
            mostrarOmitido : false,
            /*End: Control */
            selectedCiclo : {},
            loadingAsignacion : false,
            loadingDetails : false,
            loadingData :false,
            showMoreCiclo : 0,

            //For General Chart
            chartOptions :{},
            chartSeries: [],

            

            
            isPrintDialog :  false,
            titlePrint :  "",
            printBlob : null,
            loadingPrint: false,
            loadingPrintRendimiento : false,
            loadingUpdate : false,

            /*Controls */
            VIEW : TABVIEW,
            CURRENT_TAB : TABVIEW.HISTORIAL,
            resumenView : true
        }
    },
    created(){
        this.generalService =  new GeneralService(this.axios)
        this.guiaDocenteService =  new GuiaDocenteService(this.axios)
        
        this.rendimientoAcademicoService = new RendimientoAcademicoService(this.axios);
        //List Options Overlay
        this.commentsItems = [
            {
                label: 'Logros Planificación/Observación',
                clicked:  true,
                origin: ["INS_GODD","INS_PLD"],
                type: "L"
            },
            {
                label: 'Logros Comentarios de Estudiantes',
                clicked:  false,
                origin: "INS_EEDD",
                type: "L"
            },
            {
                label: 'Mejoras Planificación/Observación',
                clicked:  false,
                origin: ["INS_GODD","INS_PLD"],
                type: "M"
                
            },
            {
                label: 'Mejoras Comentarios de Estudiantes',
                clicked:  false,
                origin: "INS_EEDD",
                type: "M"
            }
        ]
    },
    async mounted(){
        //await this.generalService.getCiclo().then(result => this.cicloList  =  result)
        this.selectedCiclo =  this.$store.getters.getCicloVigente
        await this.fx_searchData();
        await this.fx_getDocenteGrupo()
    },
    methods:{

        //#region 'Grafico de Resultado'
        async fx_loadChart(){
            var data =  await this.guiaDocenteService.getInformeHorario(this.selectedDocenteGrupo.hor_codigo,this.selectedDocenteGrupo.ciclo.cil_codigo);
            this.isGenerate =  data.length > 0


            var series =  data.map(esc =>{
                return esc.ree_value
            })
            var cat =  data.map(esc =>{
                return esc.nid_nivel_dominio
            })

            this.chartOptions= {
                chart: {
                    width: 380,
                    type: 'donut',
                    toolbar: {
                        show: true
                    }
                },
                noData: {
                    text: "Sin datos que mostrar"
                },
                title: {
                    text: !this.selectedDocenteGrupo ? "N/D" : this.selectedDocenteGrupo.hor_descripcion,
                },
                subtitle: {
                    text: !this.selectedDocenteGrupo ? "N/D" : this.selectedDocenteGrupo.asignatura.plm_mat_nombre,
                    offsetY: 20,
                    style: {
                        fontSize:  '10px',
                        fontWeight:  'normal',
                        fontFamily:  undefined,
                        color:  '#9699a2'
                    },
                },
                colors:['#71c639c4', '#43b7d19f', '#fdba3fd7', '#e0781ecc', "#f1552ed8"],
                legend:{
                    offsetY: 25,
                    position: 'right'
                },
                labels:cat,
            }

            this.chartSeries = series
        },

        fx_chartResultOptions(nivelesDominioList){
            var cat = nivelesDominioList.map((esc) =>{
                return esc.nid_nivel_dominio
            })

            console.log("Data")
            var options =  {
                chart: {
                    width: "100%",
                    //height: 380,
                    type: 'pie',
                    toolbar: {
                        show: true
                    }
                },
                tooltip: {
                    y: {
                        // /* eslint-disable no-unused-vars */
                        title: {
                            //
                            formatter: (value) => {
                                if(this.selectedSeccionList.code == 0){
                                    return value;
                                }
                                var idx = nivelesDominioList.findIndex(x=> x.nid_nivel_dominio == value)
                                return `${nivelesDominioList[idx].ree_descripcion} : ` 
                            }
                        }
                    }
                },
                colors:['#71c639c4', '#43b7d19f', '#fdba3fd7', '#e0781ecc', "#f1552ed8"],
                labels: cat,
            }
            return options;
        },

        fx_chartResultSeries(nivelesDominioList){
            var series = nivelesDominioList.map( (esc) =>{
                return esc.ree_value
            })

            return series;
        }, 

        async fx_searchData(){
            this.loadingAsignacion = true
            this.loadingData =  true;
            //this.empleado = await this.empleadoService.GetById(this.codigo)
            this.docenteGrupoList =  await this.guiaDocenteService.getGrupoByDocente(this.selectedCiclo.cil_codigo, this.codigo);
            this.loadingAsignacion  = false          
        },

        async fx_getDocenteGrupo(){
            this.loadingData =  true;
            this.desempenoDocenteList = await this.guiaDocenteService.getDesempenoDocenteByDocente(this.codigo,this.selectedCiclo.cil_descripcion);
            
            this.loadingData =  false

            this.selectedDocenteGrupo = null
        },

        /* eslint-disable no-unused-vars */
        fx_filtersComentarios(tipo, omitir){

            this.selectedComentariosList = this.selectedSeccionList.comments.filter(x=> 
                tipo.origin.includes(x.dir_inst_origen) && x.dir_tipo_obs == tipo.type && (x.dir_omitido == omitir)
            )
        },
        
        
       
        async upsertGuia_onClick(evt,data){
            if(data == null){
                if(this.selectedCiclo.cil_vigente == "N")
                {
                    this.$toast.add({severity:'error', summary:'¡Error!', detail:"El ciclo no está vigente para crear visitas", life: 5000});
                    return;
                }
            }
            this.loadingDetails = true
            this.$emit("view_onEmit",{evt: evt, data:data})
             this.loadingDetails = false
        },

        //Selected Grupo=>Materia=>Horario
        async selectedMateria_onClick(dataGrupo)
        {
            this.isGenerate =  false;
            this.selectedDocenteGrupo = dataGrupo
            this.rendimientoAcademicoList = await this.rendimientoAcademicoService.getByHorario(dataGrupo.hor_codigo,dataGrupo.ciclo.cil_codigo)
            this.CURRENT_TAB = this.VIEW.RESULTADOS
            this.desempenoDocenteList = await this.guiaDocenteService.getDesempenoDocenteByDocente(this.codigo,this.selectedCiclo.cil_descripcion);
            this.fx_loadChart();
        },

        async viewHistorial_onClick(){
            this.CURRENT_TAB = this.VIEW.HISTORIAL
            await this.fx_getDocenteGrupo()
        },

        //#region ******INFORME ******
        //for overlay list: Tipos de comentarios en observaciones
        commentsToggle(event)
        {
            
            this.$refs.commentsMenu.toggle(event);
        },

        //for overlay list selectedItem: Tipos de comentarios 
        async commentsToggle_onSelected(item){
           
            this.commentsItems.forEach(x=> x.clicked = false) //set false
            this.selectedCommentsItem = {}
            item.clicked = true
            if(this.$refs.commentsMenu)
                this.$refs.commentsMenu.hide(); 
            this.selectedCommentsItem =  item

            this.fx_filtersComentarios(this.selectedCommentsItem, this.mostrarOmitido);
            
        },
        async addDesempenoToInforme(data){
            //codigo => Codigo de Visita de desempeño
            if(data.estado != "C"){
                 this.$toast.add({severity:'warn', summary:'¡Cuidado!', detail:"La visita no puede agregarse al informe porque no está cerrada", life: 5000});
                 return
            }


            this.$confirm.require({
                message: `¿Está seguro de agregar al informe esta visita. ►${(data.visita)}◄?`,
                header: "Agregar a informe",
                icon: 'pi pi-info-circle text-danger',
                acceptClass: 'p-button-primary',
                rejectClass: 'p-button-danger',
                acceptLabel : "Sí, Agregar",
                accept: async () => {
                    var result =  await this.guiaDocenteService.addToInformeResumen(data.codigo,this.$store.getters.getUserId)
                    if(result){ 
                        // if return true!
                        data.informe =  result
                        this.$toast.add({severity:'info', summary:'¡Éxito!', detail:`Se ha realizado agregado al informe, genere el informe para visualizar.`, life: 5000});
                        await this.fx_loadChart();
                    }
                    
                },
            });

           
        },

        async omitDesempenoInforme(index = 0, data ){
            
            if(this.desempenoInforme.ddi_estado != "E"){
                this.$toast.add({severity:'warn', summary:'¡Cuidado!', detail:"No es posible editar. El informe esta válidado o autorizado", life: 5000});
                return;
            }
            
            var text = data.dir_omitido ? "MOSTRAR" : "OCULTAR"
            var dataUpdate = {...data}
            this.$confirm.require({
                message: `¿Está seguro de ${text} este comentario en el informe. ►${(index + 1)}◄?`,
                header: text,
                icon: 'pi pi-info-circle text-danger',
                acceptClass: 'p-button-danger',
                acceptLabel : "Sí, actualizar",
                accept: async () => {
                    dataUpdate.dir_omitido = !data.dir_omitido
                    await this.guiaDocenteService.omitirInformeResumen(dataUpdate.dir_codigo, dataUpdate).then(res => {
                        if(res){
                            data.dir_omitido = dataUpdate.dir_omitido
                            this.selectedComentariosList.splice(index, 1); //remuevo el item
                            if(data.dir_omitido){
                                this.selectedComentariosList.push(data); //Agrego al final
                            }else{ 
                                this.selectedComentariosList.unshift(data); //Agrego al inicio
                            }
                        }
                    });
                    this.$toast.add({severity:'info', summary:'¡Éxito!', detail:`Se ha realizado la acción ${text} el comentario con éxito`, life: 5000});
                },
            });
        },

        async update_onClick(estado = "E"){
            try{
                this.loadingUpdate = true
                //Validar que no esté aprobado
                if(!this.desempenoInforme.ddi_compromiso || !this.desempenoInforme.ddi_compromiso.trim())
                    throw "Especifique los compromisos adquiridos."

                var text = (estado == 'E') ? "ACTUALIZAR" : (estado == 'A'? "APROBAR" : "AUTORIZAR")

                var data = {...this.desempenoInforme}
                data.ddi_estado = estado;
                data.ddi_codusr_validador = this.$store.getters.getUserId;
                data.ddi_codusr_autorizador = this.$store.getters.getUserId;
                this.$confirm.require({
                    message: `¿Está seguro de ${text} los compromisos acerca del grupo?`,
                    header: text,
                    icon: 'pi pi-info-circle',
                    rejectClass: 'p-button-danger',
                    acceptLabel : "Sí, Aceptar",
                    accept: async () => {
                        await this.guiaDocenteService.updateInforme(data.ddi_codigo, data).then(res=> {
                            if(res){
                                this.desempenoInforme.ddi_estado =  data.ddi_estado
                            }
                        });
                        this.$toast.add({severity:'info', summary:'¡Éxito!', detail:"Se ha realizado la acción con éxito", life: 5000});
                    },
                });
            }catch(ex){
                    var msg = methods.messageCode(ex)
                    this.$toast.add({severity:'warn', summary:'¡Ocurrió un error!', detail:msg, life: 5000});
            }
            finally{
                this.loadingUpdate = false
            }
        },
        //Generar o Ver el resumen del informe de resultados.
        async generateInforme_onClick(){
            
            try{
                //init 
                this.selectedComentariosList =  [];
                //get Info to edit for result
                this.dataInforme = {
                    revisionList: [],
                    comentarioList: [],
                    seccionList : []
                };
                var codigoInforme = 0;
                
                await this.guiaDocenteService.getInformeResumen(
                    this.selectedDocenteGrupo.hor_codigo,
                    this.selectedDocenteGrupo.ciclo.cil_codigo).then(res=>{
                    this.dataInforme = {
                        revisionList: res.revisionList,
                        comentarioList: res.resumenComentarioList,
                        seccionList: res.resumenSeccionList
                    }
                    if(res.resumenComentarioList.length > 0){
                        codigoInforme = res.resumenComentarioList[0].dir_codddi
                    }
                })

                if(!this.isGenerate)
                    throw("No hay nada que mostrar, no se ha sincronizado ninguna visita al informe");    
                

                this.desempenoInforme = await this.guiaDocenteService.getInformeById(codigoInforme);
                this.selectedArea_onClick()
                this.CURRENT_TAB = this.VIEW.INFORMES
            }catch(ex){
                var msg = methods.messageCode(ex)
                this.$toast.add({severity:'error', summary:'¡OOOPS! - Generando', detail:msg, life: 3500});
            }
        },
        selectedArea_onClick(code = 0){
            var seccion =  this.seccionGroupComputed[code];
            this.selectedSeccionList = seccion
            
            //Go to first comments list
            this.resumenView = seccion.codigo == 0
            if(!this.resumenView){
                this.commentsToggle_onSelected(this.commentsItems[0])
            }
        },
        
        //Modo de impresion del informe
        async print_onClick(){
           try{
                if(!this.isGenerate)
                    throw("No hay nada que imprimir, no se ha sincronizado ninguna visita al informe");

                this.loadingPrint =  true;
                 this.titlePrint = "Informe de Desempeño " + this.selectedDocenteGrupo.hor_descripcion
                await this.guiaDocenteService.printInforme(
                    this.selectedDocenteGrupo.hor_codigo,
                    this.selectedDocenteGrupo.ciclo.cil_codigo).then(res=>{
                    this.printBlob = res
                })  
                this.isPrintDialog = true
                
           }catch(ex){
                var msg = methods.messageCode(ex)
                this.$toast.add({severity:'error', summary:'¡OOOPS!', detail:msg, life: 3500});
           }finally{
                
                this.loadingPrint =  false;
           }
        },

        async printInforme_onClick(data){

            try{
               if(!this.rendimientoAcademicoList)
                    throw "El informe no ha sido generado. No hay nada que imprimir"
                this.loadingPrintRendimiento =  true;
                this.titlePrint = "Informe de Rendimiento Acádemico " + data.rai_deschor
                await this.rendimientoAcademicoService.printInforme(data.rai_codigo)
                .then(res=>{
                    this.printBlob = res
                })  
                this.isPrintDialog = true
                
            }catch(ex){
                    var msg = methods.messageCode(ex)
                    this.$toast.add({severity:'error', summary:'¡OOOPS!', detail:msg, life: 3500});
            }finally{
                    this.loadingPrintRendimiento =  false;
            }
        },
        //#endregion
        onRowReorder(event){
            this.selectedComentariosList =  event.value
        },
        DateTimeFormat(data,time= null) {
            if(time =="t"){
                return moment(data).format('h:mm a')
            }else if(time =="d"){
                return moment(data).format('DD/MM/YYYY')
            }else {
                return moment(data).format('DD/MM/YYYY, h:mm a')
            }
        },
        
        StatusText(estado){
            if(estado == 'P') return "Programado";
            else if(estado == 'C') return "Cerrado"
            else return "Abierto"
        },
        classTabView(tab)
        {
            return [{'text-primary' : tab === this.CURRENT_TAB}]
        },

        statusRendClass(data){
            switch(data){
                case 'A':
                    return 'warning';
                default:
                    return 'primary';
            }
        }
    },
    watch:{
        selectedCommentsItem:function(newValue){
            this.fx_filtersComentarios(newValue, this.mostrarOmitido)
        },
        mostrarOmitido:function(newValue){
            this.fx_filtersComentarios(this.selectedCommentsItem, newValue)
        }
    },
    computed:{
        seccionGroupComputed(){
            const groupBySeccion = this.dataInforme.seccionList.reduce((grouped, item) =>{
                //First Element
                if (!grouped["0"]){
                    grouped[0] = { 
                        code : 0, 
                        groupName : "RESUMEN DE RESULTADOS", 
                        isResumen: true, 
                        results : this.dataInforme.seccionList,
                        comments: this.dataInforme.comentarioList    
                    }
                }

                var tempCode =  item.rse_codigo; // for index
                grouped[tempCode] = grouped[tempCode] ?? { 
                    code : item.rse_codigo,
                    isResumen: false, 
                    groupName : item.rse_nombre, 
                    results : this.dataInforme.revisionList.filter(x=> x.rve_codrse == tempCode),
                    comments : this.dataInforme.comentarioList.filter(x=> x.dir_codrse == tempCode)
                }
                return grouped;
            },{});    
            return groupBySeccion;
        },

        desempenoDocenteFilter(){
            //Por grupo de clase
            if(this.desempenoDocenteList.length == 0){
                return []
            }
            return this.desempenoDocenteList.filter(x=> x.horario_codigo === this.selectedDocenteGrupo.hor_codigo);
        },

        statusClass(){
            switch(this.desempenoInforme.ddi_estado){
                case 'E':
                    return 'warning';
                case 'A':
                    return 'success';
                default:
                    return 'primary';
            }
        },
       
    },
    components:{
       
        AlertMessage,
        "BulbIco": Bulb,
        "LayoutGridIco" : LayoutGrid,
        "LayouTopPanelIco" : LayouTopPanel,
        "ChartBar2Ico" : ChartBar2,
        ApexCharts: VueApexCharts,
        PrintDialog,
        CardEmpleadoInfo
    }
}
</script>
<style>
    #overlay_commentsMenu .p-overlaypanel-content{
		padding: 0 !important;
	}
</style>
<style scoped>
    @media (min-width: 1400px){
        .container {
            max-width: 1320px;
        }
    }
</style>



<style lang="scss" scoped>
    .widget-box {
		border-left: 5px solid;
		&.wb-warning{
			border-left-color:#F8BE5A !important;
			&:nth-child(1){
				color:#F8BE5A
			}
			> .wb-icon {
				padding: 0.5rem;
				background: #FFF4DE;
			}
		}
		&.wb-primary{
			border-left-color:#263680 !important;
			&:nth-child(1){
				color:var(--primary-color) !important;
			}
			> .wb-icon {
				padding: 0.5rem;
				background: #c7dffe;
				color:  var(--primary-color);
			}
		}
		&.wb-danger{
			border-left-color:#ffe1e1 !important;
			&:nth-child(1){
				color: #F64E60 !important;
			}
			> .wb-icon {
				padding: 0.5rem;
				background: #fec7c7;
				color: #F64E60;
			}
		}
		&.wb-info{
			border-left-color:#7986CB !important;
			&:nth-child(1){
				color: #7986CB;
			}
			> .wb-icon {
				padding: 0.5rem;
				background: #7986CB;
				color: #303F9F;
			}
		}
		&.wb-success{
			border-left-color:#1BC3BC !important;
		}
		
	}


</style>
