<template>
    <Dialog v-model:visible="isModalVisible" :style="{width: '640px'}" :modal="true" class="p-fluid" @hide= "$emit('hide')" :closeOnEscape="false">
        <template #header>
            <div class="flex flex-column align-items-center w-full">
                <h4 class="font-semibold text-gray-900 mb-0 pb-0">Grupo por Asignatura</h4>
                <small  class="text-muted  font-normal ">Grupos de asignatura autorizadas</small>
            </div>
        </template>
        <Divider />
        <div class="p-fluid">
            <div class="formgrid grid">
                <div class="field col-12 md:col-4 my-1">
                    <Dropdown  id="cil_codigo"  v-model="selectedCiclo" :options="cicloList" placeholder="Seleccione Ciclo">
                        <template #empty>
                            Opciones no válida
                        </template>
                        <template #value="slotProps">
                            <div  v-if="slotProps.value">
                                <span>{{slotProps.value.cil_descripcion}}</span>
                            </div>
                            <span v-else>
                                {{slotProps.placeholder}}
                            </span>
                        </template>
                        <template #option="slotProps">
                            <span>{{slotProps.option.cil_descripcion}}</span>
                        </template> 
                    </Dropdown>
                </div>
                <div class="field col-12 md:col-8">
                    <Dropdown  id="fac_codigo"  v-model="selectedFacultad" :options="facultadList"   placeholder="Seleccione Facultad" class="w-full"  
                    @change="selFacultad_onChange($event)">
                        <template #value="slotProps">
                            <div  v-if="slotProps.value">
                                <span>{{slotProps.value.fac_nombre}}</span>
                            </div>
                            <span v-else>
                                {{slotProps.placeholder}}
                            </span>
                        </template>
                        <template #option="slotProps">
                            <span>{{slotProps.option.fac_nombre}}</span>
                        </template> 
                    </Dropdown>
                </div>
            </div>
            <div class="field w-full">
                <Dropdown id="car_codigo" v-model="selectedCarrera" :options="carreraList" optionLabel="car_nombre" 
                    :filter="true" placeholder="Seleccione Carrera" :showClear="true" class="w-full" :loading="loadingCarrera"  @change="selCarrera_onChange($event)">
                    <template #empty>
                        <div class="">Seleccione Facultad </div>
                    </template>
                    <template #value="slotProps">
                        <div  v-if="slotProps.value">
                            <div>{{slotProps.value.car_nombre}}</div>
                        </div>
                        <span v-else>
                            {{slotProps.placeholder}}
                        </span>
                    </template>
                    <template #option="slotProps">
                        <div>
                            <div>{{slotProps.option.car_nombre}}</div>
                        </div>
                    </template>
                </Dropdown>
            </div>
        </div>
        <div class="flex flex-column my-2">
            
            <DataTable :value="docenteGrupoList" responsiveLayout="scroll" :rowHover="true" class="p-datatable-sm w-full"
                selectionMode="Single" dataKey="plm_plm_codigo" :rows="5" :loading="loadingData" stripedRows
                :paginator="true" paginatorTemplate=" FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport  RowsPerPageDropdown"
                currentPageReportTemplate="{totalRecords} Grupos" :rowsPerPageOptions="[5,15,25]"
                :globalFilterFields="['asignatura.plm_mat_nombre']" v-model:filters="filters1"
            >
                <template #header>
                <div class="flex flex-column md:flex-row justify-content-end align-items-center mb-2">
                    <span class="p-input-icon-left  p-fluid" >
                        <i class="pi pi-search" />
                        <InputText  placeholder="Buscar Asignatura"   v-model="filters1['global'].value"/>
                    </span>
                </div>
                </template>
                <template #loading>
                    Cargando grupos, por favor espere...
                </template>
                <template #empty>
                    No hay grupos para esta carrera.
                </template>
                <Column header="Grupos">
                    <template #body="{data}">
                       <div class="flex align-items-center">
                            <div class="symbol symbol-40 symbol-circle symbol-light-primary shadow-3">
                                <span class="symbol-label font-size-h4">{{data.hor_descripcion[0].toUpperCase()}}</span>
                            </div>
                            <div class="flex flex-column ml-3">
                                <a href="javascript:;" class="text-primary font-bold text-gray-900">{{data.hor_descripcion}} 
                                    <Chip :label="data.plan.pla_nombre" class="text-xs text-muted"  />
                                </a>
                                <span class="mt-1 font-normal text-gray-600">{{data.asignatura.plm_mat_nombre}} </span>
                                <span class="mt-1 font-normal text-gray-600 text-xs">{{data.empleado.emp_nombres_apellidos}}</span>
                                 <!-- <span class="mt-1 font-normal text-gray-600 text-xs">{{data.hor_nombre}}</span> -->
                            </div>
                        </div>
                    </template>
                </Column>
                <Column headerStyle="width:130px">
                    <template #body="{data}">
                        <div class="flex justify-content-end flex-shrink-0">
                            <a href="javascript:void(0)" class="btn btn-bg-light p-2 text-primary" @click="createGuia_onClick(data)"> 
                                <i class="pi pi-chevron-right"></i> Generar
                            </a>
                        </div>
                    </template>
                </Column>
            </DataTable>
        </div>
    </Dialog>

</template>

<script>
import FacultadService from '@/service/facultadService'
import EmpleadoService from '@/service/empleadoService'
import GeneralService from '@/service/generalService'

import {FilterMatchMode} from 'primevue/api';

export default {
    emits:["hide", "create_onEmit"],
    facultadService : null,
    empleadoService : null,
    generalService : null, 
    props:{
        Service: null,
        Searching: {
            type: Boolean,
            default: false
        },
        
    },
    data(){
        return {
            docenteGrupoList : [],
            
            facultadList: [],
            selectedFacultad : null,
            
            carreraList: [],
            selectedCarrera : null,
            loadingCarrera: false,

            cicloList : [],
            selectedCiclo : null,
            
            isModalVisible :  this.Searching,
            isDialogEmpleado: false,

            loadingData: false,
            filters1: {
                'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
             }
        }
    },
    async created(){
        this.facultadService =  new FacultadService(this.axios)
        this.empleadoService =  new EmpleadoService(this.axios)
        this.generalService =  new GeneralService(this.axios)
    },
    async mounted(){
        try{
            await this.generalService.getCiclo().then(result => this.cicloList  =  result )

            /*Uso de restricción por facultad asignada */
            await this.facultadService.getAllFacultad().then(result => {
                this.facultadList =  result
                var fac  =  this.facultadList.filter(x=> x.fac_codigo == this.$store.state.uEmpleado.emp_codfac);
                if(fac.length > 0){
                    this.selectedFacultad =  fac[0];
                    this.facultadList =  fac;
                    this.selFacultad_onChange({ value :  { fac_codigo :  this.selectedFacultad.fac_codigo} })
                }
            })
        }catch(ex){
            this.$catchError(ex)
        }
    },
    methods:{
       
         async selFacultad_onChange(event){
            this.selectedCarrera = null;
            this.selectedAsignatura = null;
            this.loadingCarrera = true;
            await this.facultadService.getCarreraByFacultad(event.value.fac_codigo).then(data => this.carreraList = data)
            this.loadingCarrera = false;
        },

        async selCarrera_onChange(evt){

            if(evt.value === null){
                return;
            }
            this.loadingData =  true;
            this.docenteGrupoList =  await this.Service.getGrupoByCarrera(this.selectedCiclo.cil_codigo, evt.value.car_codigo);
            this.loadingData =  false
        },

        async createGuia_onClick(data){
            var resultData =  {...data}
            //Empleado property
            resultData["empleado"] =  await this.empleadoService.GetById(data.empleado.emp_codigo)
            this.$emit("create_onEmit",resultData)
            this.isModalVisible =  false;
        }
    },
    computed:{},
    components:{
    }
}
</script>

<style scoped lang="scss">
::v-deep(.p-paginator) {
    .p-paginator-current {
        margin-left: auto;
    }
}


</style>
