<!-- This template required:
    Components -> [PlanificacionData] from @/components/LaborDocente/Planificacion/PlanTable
    Components -> [Icons] from components/svg/_svgHelper.js

    Service -> [PlanDidacticoService] from service/planDidacticoService
    Service -> [Service] by Reference => from /service/guiaDocenteService

    Only add or edit one data from Desemepeño Docente
-->

<template>
    <Dialog v-model:visible="isModalVisible" :style="{width: '640px'}" :modal="true" class="p-fluid" @hide="cancel_onClick($event)" :closeOnEscape="false">
        <template #header >
            <div class="flex flex-column align-items-center w-full">
                <h4 class="font-semibold text-gray-900 mb-0 pb-0">Guía observación docente</h4>
                <small  class="text-muted text-base  font-semibold text-center">Aplicación de la guía de observación desempeño docente</small>
            </div>
        </template>
        <!--Begin: Header C. If InitValue has value -->
        <div class="mt-2" v-if="Init">
            <div class="flex justify-content-end mb-2" v-if="guiaDocente.ddo_codigo > 0">
                <a href="javascript:;" class="mx-2 hover:text-primary font-semibold" @click="generateVisita_onClick()">Nueva Visita <i class="pi pi-ellipsis-v"/></a>
            </div>
            <div class="flex" > 
                <div class="flex-shrink-1  hidden md:inline-flex">
                    <div class="flex flex-column align-items-center">
                        <div class="symbol symbol-50 symbol-lg-120 symbol-light-info shadow-3 mb-2">
                            <span class="text-2xl symbol-label font-bold">V{{guiaDocente.ddo_num_visita}} </span>
                        </div>
                        <small class="font-semibold text-primary text-xs">VISITA</small>
                    </div>
                    
                </div>
                <div class="flex-grow-1 ml-3">
                    <div class="flex align-items-center justify-content-between flex-wrap mt-1 flex-wrap">
                        <div class="mr-1">
                            <a href="javascript:void(0)" class="font-bold mr-2 text-lg text-gray-800 hover:text-primary uppercase" >{{guiaDocente.asignatura.plm_mat_nombre}}</a>
                            <div class="flex flex-column  md:flex-row flex-wrap my-2 text-xs ">
                                <a href="javascript:void(0)" class=" flex align-items-center text-muted font-semibold hover:text-primary mb-2  mr-2"> 
                                    <i class="pi pi-th-large pi-fw  mr-1"></i> <span> {{guiaDocente.facultad.fac_nombre}}</span></a>
                                <a href="javascript:void(0)" class="flex align-items-center text-muted font-semibold hover:text-primary  mb-2 mr-2"> 
                                    <i class="pi pi-shield pi-fw  mr-1"></i> {{guiaDocente.carrera.car_nombre}}</a>
                                <a href="javascript:void(0)" class="flex align-items-center text-muted font-semibold hover:text-primary  mb-2  mr-2"> 
                                    <i class="pi pi-book pi-fw  mr-1"></i> {{guiaDocente.plan.pla_nombre}}</a>
                            </div>
                        </div>  
                    </div>
                    <div class="flex justify-content-end">
                        <a href="javascript:void(0)" class="label label-lg label-light-primary label-inline font-semibold ml-1 py-3 my-1">Grupo: {{guiaDocente.ddo_hor_descripcion}} </a>
                    </div>
                </div>
            </div>
            <Divider class="p-0 my-2"/>
            <div class="flex flex-row mx-0 justify-content-between align-items-center flex-wrap">
                <div class="flex flex-column">
                    <div class="flex align-items-center flex-lg-fill ">
                        <span class="mr-3">
                            <i class="pi pi-share-alt text-4xl  text-primary"></i>
                        </span>
                        <a href="javascript:;" class=" text-primary font-semibold mx-2 uppercase">{{guiaDocente.empleado.emp_nombres_apellidos}}</a>
                    </div>
                    <small class="text-center text-xs">
                    👨‍💼 {{guiaDocente.empleado.emp_puesto}} 💻 {{guiaDocente.empleado.emp_unidad}}
                    </small>
                </div>
                <div class="flex flex-column">
                    <div class="flex align-items-center flex-lg-fill justify-content-center">
                        <span class="mr-2">
                            <i class="pi pi-user text-4xl  text-primary"></i>
                        </span>
                        <a href="javascript:;" class=" text-primary font-semibold mx-2 uppercase">{{guiaDocente.ddo_est_inscritos}}</a>
                    </div>
                    <small class="text-center text-xs"> Inscritos</small>
                </div>

                <div class="flex flex-column">
                    <div class="flex align-items-center flex-lg-fill justify-content-center">
                        <span class="mr-2">
                            <i class="pi pi-users text-4xl  text-danger"></i>
                        </span>
                        <a href="javascript:;" class=" text-danger font-semibold mx-2 uppercase">{{guiaDocente.evaluados}}</a>
                    </div>
                    <small class="text-center text-xs"> Evaluaciones</small>
                </div>
                <router-link  v-if="IsCreated" v-tooltip.top="'Ir a guía'"  class="p-button  p-button-icon-only p-button-text"  :to="{ name: 'Rev_DesempenoDoc_Layout', params: { id:  guiaDocente.ddo_codigo} }" tag="button">
                    <span class="pi pi-arrow-right pi-pw p-button-icon"></span>
                </router-link>
            </div>
            <Divider class="p-0 my-2"/>
            <!--BEGIN: Form -->
            <form @submit.prevent="form_onHandleSubmit(!v$.$invalid)" class="p-fluid mt-4" autocomplete="off" :style=" IsClosed ? 'pointer-events: none;' : ''">
                <div class="formgird grid ">
                    <div class="field col">
                        <label for="ddo_fecha_visita" class="font-semibold" :class="{'p-error':submitted}">Fecha Visita</label>
                        <div class="p-inputgroup">
                            <span class="p-inputgroup-addon">
                                <i class="pi pi-calendar text-primary"></i>
                            </span>
                                <Calendar id="pbd_fecha_desde" v-model="guiaDocente.ddo_fecha_visita"  :showIcon="false" :manualInput="false" dateFormat="dd/mm/yy" :showTime="true"/>
                        </div>
                    </div>

                     <!--** Only Edit Mode  -->
                    <div class="field col-4  md:col-4 text-right" v-if="IsCreated">
                        <label for="ddo_est_presentes" class="font-semibold"  >No. Presentes</label>
                        <div class="flex  justify-content-end"> 
                            <div class="p-inputgroup w-10rem">
                                <span class="p-inputgroup-addon">
                                    <i class="pi pi-user text-primary"></i>
                                </span>
                                <InputNumber  for="ddo_est_presentes" v-model="guiaDocente.ddo_est_presentes" inputClass="text-right"/>
                            </div>
                        </div>
                         <small class="p-error text-xs">* Estudiantes presentes</small>
                    </div>
                </div>

                <div class="field">
                    <label for="ddo_lugar" class="font-semibold">Lugar</label>
                    <InputText id="plb_nombre_tec" v-model="guiaDocente.ddo_lugar" placeholder="Lugar de visita"/>
                    <div class="flex justify-content-between">
                        <span class="form-text text-muted"></span>
                    </div>
                </div>

                <!-- Plan academico -  Resource -->
                <!-- ** Uso de buscador ** -->
                <div class="flex border-round  p-4 mt-4 border-dashed-custom   mb-3 " :class="!selectedPlanDidactico ? 'border-warning bg-light-warning' :  'border-primary bg-light-primary'"> 
                    <div class="block" v-if="!selectedPlanDidactico">
                        <a href="javascript:;" class="font-bold mx-1 text-primary">PLANEACIÓN DIDÁCTICA</a> 
                        Asociarla a esta visita para el informe de resultados posterior.
                        <a href="javascript:void(0)" class="font-bold ml-1 text-primary" @click="addPlan_onClick()">Asociar</a>
                    </div> 
                    <div class="flex flex-column  w-full" v-else>
                        <span class="flex font-semibold"> <ComponentIco/>  Competencia </span>
                        <p class=" flex  flex-column align-items-center mt-1 mx-3" > {{selectedPlanDidactico.pld_com_asignatura}} </p>

                        <div class="flex justify-content-end">
                             <a href="javascript:void(0)" class=" text-xs font-bold ml-1 text-primary" @click="addPlan_onClick()">
                                 <i class="pi pi-paperclip" /> {{selectedPlanDidactico.pld_asignatura}} {{selectedPlanDidactico.pld_nomcil}}  - {{selectedPlanDidactico.pld_plan_acad.toUpperCase()}}</a>
                        </div>
                    </div>
                </div>

                <!--BEGIN: Didactica Dynamic -->
                <!--** Only Edit Mode  -->
                <div class="p-fluid" v-if="IsCreated">
                    <div class="field">
                        <label for="ddo_tecnica" class="font-semibold">Didáctica utilizada</label>
                        <div class="p-inputgroup">
                            <InputText placeholder="Agregar didáctica" ref="txt_tecnica"/>
                            <Button icon="pi pi-plus-circle" class="p-button-primary" @click="addTecnica_onClick()"/>
                            <Button icon="pi pi-search" class="p-button-info btn-light-primary" @click="searchPreTecnica_onClick()"/>
                        </div>
                        <div class="flex flex-wrap border-round  px-2 py-2 my-2  border-dashed-custom  border-warning"> 
                            <Chip :label="didactica" v-for="(didactica, index) in DidacticaList" :key="index + didactica" class="mb-2 mx-1" removable  @remove="deleteTecnica_onClick(index)"/>
                            <p v-if="DidacticaList.length == 0" class="font-italic w-full text-center">No hay didáctica que mostrar.</p>
                        </div>
                        <small class="p-error">*Didáctica utilizada.</small>
                    </div>
                </div>
                <!--END: Didactica Dynamic -->

                <!--** Only Edit Mode  -->
                <div class="flex flex-column md:flex-row my-2" v-if="IsCreated">
                    <div class="font-bold flex-grow-1 flex-shrink-1" >
                        <label class="text-base">Saberes desarrollados</label>
                        <div class="text-gray-400 text-sm">(marcar los que corresponda)</div>
                    </div>
                    <div class="flex align-items-end justify-content-end  my-2">
                        <div class="field-checkbox">
                            <Checkbox id="cbinary"  name="saberes" v-model="itemSaberes.conocer" :binary="true" />
                            <label for="cbinary">Conocer</label>
                        </div>
                        <div class="field-checkbox mx-1">
                            <Checkbox id="hbinary" name="saberes" v-model="itemSaberes.hacer" :binary="true" />
                            <label for="hbinary">Hacer</label>
                        </div>
                        <div class="field-checkbox">
                            <Checkbox id="scbinary" name="saberes" v-model="itemSaberes.convivir" :binary="true"/>
                            <label for="scbinary">Ser y Convivir</label>
                        </div>
                    </div>
                </div>

                <!--** Only Edit Mode  -->
                <div class="flex flex-column md:flex-row my-2" v-if="IsCreated">
                    <div class="font-bold flex-grow-1 flex-shrink-1" >
                        <label class="text-base">Escala de Estimación de Desempeño Docente</label>
                        <div class="text-gray-400 text-sm">Activar evaluación en el portal de estudiante</div>
                    </div>
                    <div class="flex align-items-end justify-content-end  my-2">
                        <div class="field-checkbox">
                            <InputSwitch id="ddo_estado"  name="saberes" v-model="modeEvaluacion"/>
                            <label for="ddo_estado" class="text-muted">Activar</label>
                        </div>
                        
                    </div>
                </div>
                <Divider />
                <div class="flex flex-column md:flex-row my-2" v-if="IsCreated">
                    <div class="font-bold flex-grow-1 flex-shrink-1" >
                        <label class="text-base">Finalizar la evaluación</label>
                        <div class="text-gray-400 text-sm">Dar por finalizado/cerrado esta visita</div>
                    </div>
                    <div class="flex align-items-end justify-content-end  my-2">
                        <div class="field-checkbox">
                            <InputSwitch id="ddo_estado"  name="saberes" v-model="modeCerrado" class="inputswitch"/>
                            <label for="ddo_estado" class="text-muted">Cerrar</label>
                        </div>
                        
                    </div>
                    
                </div>
                <!--BEGIN: Footer form -->
                <!-- Close Message-->
                <div class="flex border-round  p-4 mt-4 border-dashed-custom border-danger bg-light-danger mb-3 " v-if="IsClosed" > 
                    <div class="block">
                        <a href="javascript:;" class="font-bold mx-1 text-primary">GUIA DE OBSERVACION DOCENTE</a> 
                        El proceso de evaluación de la visita ha finalizado y está disponible para el  
                        <a href="javascript:void(0)" class="font-bold ml-1 text-primary">INFORME DE RESULTADOS.</a>
                    </div> 
                   
                </div>
                <!-- Control Buttons -->
                <div class="flex justify-content-center mt-3" v-else>
                    <Button label="Cancelar" class="p-button-text p-button-danger w-10rem mx-2 font-semibold" @click="cancel_onClick($event)" />
                    <Button type="submit" :label=" IsCreated ? 'Actualizar' : 'Crear'" class="mt-2 w-10rem" :loading="loadingCreate"/>
                </div>
                <!--END: Footer form -->
            </form>
            <!--END: Form -->
        </div>
        <!--End: Header -->

        <!--Begin: Selected Init -->
        <div class="block text-center p-2" v-else>
            <Button type="button" label="Buscar Docente" icon="pi pi-user" class=" max-w-max mx-1 p-button-info btn-light-primary mr-2"  @click="create_onClick('D')"/> 
            <Divider align="center" class="">
                <span class="p-tag">o</span>
            </Divider>
            <Button type="button" label="Buscar Carrera" icon="pi pi-book" class=" max-w-max mx-1 p-button-primary  mr-2"  @click="create_onClick('C')"/> 
        </div>
        <!--End: Selected Init -->
     

        <template #footer class="">
            <Divider/>
            <div class="flex mt-5">
                <div class="block font-bold text-left">
                    <label class="">Agregando una guía de observación docente</label>
                    <div class="text-sm text-gray-400">Esquema de evaluación (GODD,EEDD) . Si necesita más información revise los esquemas de revisión.
                        <span class="text-xs">►Ref.{{this.guiaDocente.ddo_codigo}}◄</span>
                    </div>
                </div>
            </div>
        </template> 
    </Dialog>


    <!--Begin: Dialogo Buscar Criterio -->
    <Dialog v-model:visible="isDialogTecnica" :modal="true" :style="{width: '520px'}">
        <template #header>
            <div class="flex flex-column align-items-center w-full">
                <h4 class="font-semibold text-gray-900 mb-0 pb-0">Técnicas didácticas</h4>
                <small  class="text-muted  font-normal ">Técnicas utilizadas según Modelo Educativo</small>
            </div>
        </template>
        <div class="my-2">
            <div class="flex flex-column md:flex-row justify-content-end align-items-center mb-2 " >
                <span class="p-input-icon-left  p-fluid w-full" >
                    <i class="pi pi-search" />
                    <InputText  placeholder="Buscar Técnica"   v-model="filtersTecnica['global'].value"/>
                </span>
            </div>
            <DataTable :value="tipoTecnicaList" responsiveLayout="scroll" :rowHover="true" :rows="10" class="p-datatable-sm"
                v-model:selection="selectedTecnica" :selectionMode="'multiple'" dataKey="ttd_nombre" :metaKeySelection="false"
                :paginator="true" paginatorTemplate=" FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport  RowsPerPageDropdown"
                currentPageReportTemplate="Mostrando {first} - {last} de {totalRecords} "
                :rowsPerPageOptions="[10,25,50]"
                :globalFilterFields="['ttd_nombre']" v-model:filters="filtersTecnica">
                <template #loading>
                    Cargando técnicas didácticas, por favor espere...
                </template>
                <template #empty>
                    No hay técnicas didácticas en la base de datos.
                </template>
                <Column selectionMode="multiple" headerStyle="width: 3rem"></Column>
                <Column field="ttd_nombre" header="Descripción"/>
            </DataTable>
        </div>

        <template #footer>
            <Button type="button" label="Cancelar" class="mt-2 p-button-danger p-button-text " @click="cancelPreTecnica_onClick()" />
            <Button type="submit" label="Seleccionar" class="mt-2 " @click="addPreTecnica_onSelected()" />
        </template>
    </Dialog>
    <!--Begin: Dialogo Buscar Criterio -->

    <!--Begin: Dialogo Buscar Criterio -->
    <Dialog v-model:visible="isDialogPlanD" :modal="true" :style="{width: '640px'}">
        <template #header>
            <div class="flex flex-column align-items-center w-full">
                <h4 class="font-semibold text-gray-900 mb-0 pb-0">Planeación Didáctica</h4>
                <small  class="text-muted  text-base font-semibold ">Correspondientes al período académico</small>
            </div>
        </template>
        <div class="my-2">
             <PlanDidacticoData :Service="planDidacticoService" :Facultad="guiaDocente.facultad" :Carrera="guiaDocente.carrera" :EditMode="false" @onSelected="selectedPlan_onClick($event)"/>
        </div>

        <template #footer>
             <div class="flex justify-content-center mt-3">
                <Button type="button" label="Cancelar" class="mt-2 p-button-danger p-button-text " @click="isDialogPlanD =  false" />
             </div>
        </template>
    </Dialog>
    <!--Begin: Dialogo Buscar Criterio -->
</template>
<script>

//import { required, helpers, maxLength  } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import {FilterMatchMode} from 'primevue/api';
import PlanDidacticoService from '@/service/planDidacticoService'

import PlanDidacticoData from "@/components/LaborDocente/Planificacion/PlanDidacticoData" 
import {Component } from '@/components/svg/_svgHelper.js'
import methods from "@/utilities/generalMethods.js"
import moment from 'moment'

/*
*/
    export default {
        emits:["hide", "selectedType_onEmit", "update_onEmit"],
        props:{
            Service:  null,
            Upsert:{
                type: Boolean,
                default: false
            },
            Mode: {
                type : String,
                default : "Create"
            },
            Init: null, // For init value (facultad, carrera,plan,)
        },
        planDidacticoService : null,
        setup: () => ({ v$: useVuelidate() }),
        data(){
            return {
                guiaDocente:{},

                tipoTecnicaList: [],
                selectedTecnica: [],
                itemSaberes:{
                    conocer: "0",
                    hacer: "0",
                    convivir : "0"
                },

                modeEvaluacion: false,
                modeCerrado: false,
                
                selectedPlanDidactico : null,

                loadingCarrera : false,                
                submitted : false,

                isModalVisible :  this.Upsert,
                loadingCreate : false,
                isDialogTecnica : false,
                isDialogPlanD : false,
                filtersTecnica: {
                    'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
                }
                
                
            }
        },
        validations() {
            return {
            }
        },
        created(){
            if(this.Init){
                this.guiaDocente = this.Init
                //Get status
                this.modeEvaluacion =  this.guiaDocente.ddo_estado == 'A'
                this.modeCerrado =   this.guiaDocente.ddo_estado == 'C'
            }
            this.planDidacticoService =  new PlanDidacticoService(this.axios)
        },
        async mounted(){
            
            this.tipoTecnicaList = await this.Service.getAllTipoTecnica();

            if(this.Init !== null && this.Init.ddo_codpld != 0) {
                await  this.fx_GetPlanDidactico(this.Init.ddo_codpld);
                this.itemSaberes =  {
                    conocer: this.Init.ddo_saberes[0] == "1",
                    hacer: this.Init.ddo_saberes[1] == "1",
                    convivir : this.Init.ddo_saberes[2] == "1"
                }
            }
        },
        methods:{
            /**
             * Obtener el plan didactico asociado a la revision/visita 
             * @param {Integer} codigo Código de plan Didáctico
            */
            async fx_GetPlanDidactico(codigo)
            {
                this.selectedPlanDidactico  = null;
                await this.planDidacticoService.getPlan(codigo,0).then(res => {
                    if(res == ""){
                        throw "No se encontrarón resultados o no está autorizado"
                    }
                    this.selectedPlanDidactico = res
                })
            },
            addTecnica_onClick(){
                //for new  custom tag/chip
                var value = this.$refs.txt_tecnica.$el.value.trim();
                if(!value) return;

                //add item with separator [,]
                var separator = this.guiaDocente.ddo_tecnica.length != 0 ? "," : "";
                this.guiaDocente.ddo_tecnica += separator + value

                this.$refs.txt_tecnica.$el.value =  ""
            },

            generateVisita_onClick(){
                this.guiaDocente.ddo_lugar = "",
                this.guiaDocente.ddo_fecha_visita =  new Date()
                this.guiaDocente.ddo_codigo =  0
                this.guiaDocente.ddo_estado = "P"
                this.guiaDocente.ddo_num_visita = "N"
                this.guiaDocente.ddo_tecnica = ""
                this.guiaDocente.ddo_saberes = "000"


                this.modeEvaluacion  = false
                this.modeCerrado =  false
            },

            searchPreTecnica_onClick(){
                if(!this.guiaDocente.ddo_tecnica || !this.guiaDocente.ddo_tecnica.trim())  this.selectedTecnica = []
                else {
                    //create structure to map 
                    var list =  this.guiaDocente.ddo_tecnica.split(",")
                    this.selectedTecnica = list.map( (value)  => { 
                        return { ttd_nombre : value }
                    });
                }
                this.isDialogTecnica  =  true;
            },

            cancelPreTecnica_onClick(){
                //for dialogo
                this.isDialogTecnica =  false;
                this.selectedTecnica =  []
            },

            addPreTecnica_onSelected(){
                //for dialog
                if(this.selectedTecnica.length == 0) return;

                var value = this.selectedTecnica.map((item)=> item.ttd_nombre).join(",")
                this.guiaDocente.ddo_tecnica =  value;
                this.isDialogTecnica =  false;
            },

            deleteTecnica_onClick(idx){
                var list =  this.guiaDocente.ddo_tecnica.split(",");
                list.splice(idx,1);
                this.guiaDocente.ddo_tecnica = list.join(",")
            },


            addPlan_onClick(){
                this.isDialogPlanD =  true
            },

            async selectedPlan_onClick(evt){
                //evt is data result
                this.isDialogPlanD =  false
                await this.fx_GetPlanDidactico(evt.pld_codigo);
                this.guiaDocente.ddo_codpld = this.selectedPlanDidactico.pld_codigo
            },

            create_onClick(type){
                //type => C : Carrera    Type => D : Docente
                this.$emit("selectedType_onEmit",type)
                this.cancel_onClick();
            },

            cancel_onClick(){
                this.isModalVisible =  false;
                this.$emit("hide");
            },

            async form_onHandleSubmit(event){
                try{
                    this.loadingCreate =  true;
                    this.submitted = true;
                    if (!event) {
                        return;
                    }
            
                    if(!this.IsCreated){
                        await this.Service.createGuiaDesempeno({
                            codpld : this.guiaDocente.ddo_codpld,
                            codemp : this.guiaDocente.ddo_codemp,
                            lugar :  this.guiaDocente.ddo_lugar,
                            codhor : this.guiaDocente.ddo_codhor,
                            fecha_visita: moment(this.guiaDocente.ddo_fecha_visita).format("YYYY-MM-DDTHH:mm"),
                            hor_descripcion :  this.guiaDocente.ddo_hor_descripcion,
                            est_inscritos : this.guiaDocente.ddo_est_inscritos,
                            codreg : this.guiaDocente.ddo_codreg,
                            codcil : this.guiaDocente.ddo_codcil,
                            codigo : this.guiaDocente.ddo_codigo,
                            codusr : this.$store.getters.getUserId
                        }).then(res => this.guiaDocente = res);
                        this.$toast.add({severity:'info', summary:'¡Éxito!', detail:"Se ha programado visita con éxito", life: 5000});
                        this.$emit("update_onEmit")
                    }else{
                        //Update This evaluation
                        var saberes = `${+ this.itemSaberes.conocer}${+this.itemSaberes.hacer}${+this.itemSaberes.convivir}`
                        var status = this.modeEvaluacion ? "A" : "P"
                        status = this.modeCerrado ? "C" : status;

                        var text =  "Actualizar la programación en"
                        if (status == "C") text =  "Finalizar"
                        else if(status == "A") text ="Habilitar portal"

                        //Validacion antes de cerrar
                        if(status == "C")
                        {
                            if(!this.guiaDocente.ddo_lugar || !this.guiaDocente.ddo_lugar.trim())
                                throw "Ingrese un dato correcto en lugar de realización de la evaluación."
                            else if(!this.guiaDocente.ddo_tecnica || !this.guiaDocente.ddo_tecnica.trim())
                                throw "Ingrese al menos una técnica o didáctica utilizada."
                        }
                        this.$confirm.require({
                            message: `¿Está seguro de ${text} esta visita (${this.guiaDocente.ddo_num_visita}) del grupo ${this.guiaDocente.ddo_hor_descripcion}?`,
                            header: text,
                            icon: 'pi pi-info-circle',
                            acceptClass: 'p-button-danger',
                            acceptLabel : "Sí, actualizar",
                            accept: async () => {
                                await this.Service.updateGuiaDesempeno(this.guiaDocente.ddo_codigo, {
                                    codpld : this.guiaDocente.ddo_codpld,
                                    tecnica : this.guiaDocente.ddo_tecnica,
                                    saberes : saberes,
                                    lugar :  this.guiaDocente.ddo_lugar,
                                    fecha_visita: moment(this.guiaDocente.ddo_fecha_visita).format("YYYY-MM-DDTHH:mm"),
                                    est_presentes : this.guiaDocente.ddo_est_presentes,
                                    estado : status,
                                    codigo : this.guiaDocente.ddo_codigo 
                                }).then();
                                this.guiaDocente = await this.Service.getDesempenoDocenteById(this.guiaDocente.ddo_codigo)
                                this.$toast.add({severity:'info', summary:'¡Éxito!', detail:"Se ha actualizado la visita con éxito", life: 5000});
                                this.$emit("update_onEmit")
                            },
                        });
                    }
                    
                }catch(ex){
                    var msg = methods.messageCode(ex)
                    this.$toast.add({severity:'warn', summary:'¡Ocurrió un error!', detail:msg, life: 5000});
                }
                finally{
                    this.loadingCreate = false
                }
            }

        },
        computed:{
            IsCreated(){
                return this.guiaDocente.ddo_codigo > 0;
            },
            IsClosed(){
                if(this.guiaDocente.ddo_estado == 'C')
                    return true;
                else
                    return false
            },
            IsEditMode(){
                return this.Mode == 'Edit'
            },
            DidacticaList(){
                if(!this.guiaDocente.ddo_tecnica){
                    return []
                }

                else if(this.guiaDocente.ddo_tecnica.trim().length == 0)
                    return [];
                else
                    return this.guiaDocente.ddo_tecnica.split(",")
            },

            InitialName(){
                if(this.selectedEmpleado.emp_nombres_apellidos)
                    return this.selectedEmpleado.emp_nombres_apellidos[0].toUpperCase()

                return "N/D"
            },
            InitAsignatura(){
                if(this.selectedEmpleado.emp_nombres_apellidos)
                    return this.selectedAsignatura.plm_mat_nombre.trim()[0].toUpperCase()

                return "N/D"
                
            }
        },
        components:{
            PlanDidacticoData,
            ComponentIco : Component
        }
    }
</script>
<style >
    .p-chip-remove-icon{
        color: #73000c !important;
    }

    .inputswitch.p-inputswitch-checked .p-inputswitch-slider {
        background: #e54646;
    }
    .inputswitch.p-inputswitch-checked:not(.p-disabled):hover .p-inputswitch-slider {
        background: #ca3838;
    }
    .inputswitch.p-focus .p-inputswitch-slider {
        outline: 0 none;
        outline-offset: 0;
        box-shadow: 0 0 0 1px #f16363;
    }
</style>
<style scoped>
    .p-avatar.p-avatar-hover:hover {
        -webkit-transition: all 0.3s ease;
        transition: all 0.3s ease;
        z-index: 1;
    }
    .p-avatar.p-avatar-hover {
        cursor: pointer;
    }
</style>
